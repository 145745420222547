import React, { useState, useEffect, useRef } from 'react'
import { default as Seleccion } from 'react-select';

import './style.css'

const TableResponsive = (props) => {
    const def = [1, 5, 10, 25, 50, 100];

    const getDataFilerColums = () => {
        let data = props.columns.filter(e => {
            if (e.key !== 'boxcolor' && e.key !== 'controls') return e;
        }).map(e => ({ value: e.key, label: e.text }))
        return data;
    }

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(props.perPage);
    const [pageCont, setPageCont] = useState(0);
    const [optionsPerPage, setOptionsPerPage] = useState(def.map(e => ({ value: e, label: e })))
    const [valueSelect, setValueSelect] = useState({ value: props.perPage, label: props.perPage })
    const [valueSelectFilter, setValueSelectFilter] = useState(getDataFilerColums().find(e => e.value === props.defaultFilter) != undefined ? getDataFilerColums().find(e => e.value === props.defaultFilter) : getDataFilerColums()[0])
    const [strFilter, setStrFilter] = useState('');
    const [orderBy, setOrderBy] = useState({ value: "ascendente", label: "Ascendente" })

    const oerderBy = [
        { value: "ascendente", label: "Ascendente" },
        { value: "descendente", label: "Descendente" }
    ]

    const normalizeHeight = (selector, add = 0) => {
        const d = document.querySelector(selector);
        if (d && d.style) {
            let pos = d.getBoundingClientRect().x;
            d.style.minHeight = ((window.innerHeight + add) - pos) + 'px';
            window.addEventListener('resize', function () {
                d.style.minHeight = ((window.innerHeight + add) - pos) + 'px';
            });
        }
    }

    useEffect(() => {
        setPageCont(Math.ceil(props.data.length / pageSize));
    }, [props.data, pageSize])

    useEffect(() => {
        if (props.autoHeight) {
            normalizeHeight('.bktable-container')
            normalizeHeight('.wrapper-responsive', -100)
        }
    }, [])

    const paginate = () => {
        return props.data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const previusPage = () => {
        setPageNumber(pageNumber - 1);
    }

    const createButonIndex = () => {
        let btns = []
        for (let i = 1; i <= pageCont; i++) {
            btns.push(<button onClick={() => setPageNumber(i)} href="#" className={`${props.paginationClass} ${pageNumber == i ? 'active' : ''}`}>{i}</button>)
        }
        return (btns.map(btn => <li className="page-item" >{btn}</li>))
    }

    const spliKey = (data, key) => {
        if (key.includes('.')) {
            return eval(`data${key.split('.').map(r => `['${r}']`).join('')}`)
        } else {
            return data[key];
        }
    }

    const getTd = (e, i) => {
        return <div className="row-responsive">
            {props.columns.map((c, o) => {
                if (c['elem'] != undefined) return <div className="cell-responsive" style={{ textAlign: c['alignContent'] != undefined ? c['alignContent'] : c['key'] === "controls" ? 'right' : 'left', minWidth: c['minWidth'] != undefined ? c['minWidth'] : '100%' }} data-title={c['text']}>{c['elem'](c['data'] != undefined || null ? e[c['data']] : e)}</div>
                else if (c['key'] === 'boxcolor') return <div className="cell-responsive" style={{ textAlign: c['alignContent'] != undefined ? c['alignContent'] : 'left' }} data-title={c['text']}> <div style={{ width: 15, height: 15, borderRadius: 3, backgroundColor: e[c['color']], borderColor: e[c['color']] }}></div> </div>
                else {
                    let key = spliKey(e, c['key']);
                    return <div className="cell-responsive" style={{ textAlign: c['alignContent'] != undefined ? c['alignContent'] : 'left' }} data-title={c['text']}>{key} {c['last']}</div>
                }
            })}
        </div>
    }

    const filterContainer = (data) => {
        return data.filter(e => {

            if (String(e[valueSelectFilter.value]).toLocaleLowerCase().includes(strFilter.toLocaleLowerCase())) {
                return e;
            }
        })
    }

    const onDataTable = () => {
        let data = filterContainer(props.data);
        if (props.dataTable != undefined) props.dataTable(data.map(e => e[props.key]));
    }

    const onExportsCSV = () => {
        let data = filterContainer(props.data);
        if (props.onExportsCSV != undefined) props.onExportsCSV(data.map(e => e[props.keyID]));
    }

    const onExportsXLS = () => {
        let data = filterContainer(props.data);
        if (props.onExportsXLS != undefined) props.onExportsXLS(data.map(e => e[props.keyID]));
    }

    const onExportsPDF = () => {
        let data = filterContainer(props.data);
        if (props.onExportsPDF != undefined) props.onExportsPDF(data.map(e => e[props.keyID]));
    }

    return (
        <div className="bktable-container">
            <div className="container-flex control-container">
                <div className="col-filter">
                    <div className="container-flex">
                        <div className="filter-label">
                            <label>Buscar por:</label>
                        </div>
                        <div className="filter-select">
                            <Select placeholder="Items por pagina" options={getDataFilerColums()} value={valueSelectFilter} onChange={v => {
                                setValueSelectFilter(v);
                            }} />
                        </div>
                        <div className="filter-input">
                            <input type="text" placeholder="Buscar..." onChange={v => setStrFilter(v.target.value)} />
                        </div>
                    </div>
                </div>
                {props.order ? <div className="col-sort">
                    <div className="container-flex">
                        <div className="sort-label">
                            <label>Ordenar por:</label>
                        </div>
                        <div className="sort-list">
                            <Select placeholder="Items por pagina" options={getDataFilerColums()} value={valueSelectFilter} onChange={v => {
                                // setValueSelectFilter(v);
                            }} />
                        </div>
                    </div>
                </div> : null}
                <div className="col-order">
                    {props.order ? <div className="container-flex">
                        <div className="order-label">
                            <label>Ordenar como:</label>
                        </div>
                        <div className="order-list">
                            <Select placeholder="Items por pagina" options={oerderBy} value={orderBy} onChange={v => {
                                setOrderBy(v);
                            }} />
                        </div>
                    </div> : null}

                </div>
                <div className="col-perpage">
                    {props.pagination ? <div className="container-flex">
                        <div className="select-label">
                            <label>Por pagina</label>
                        </div>
                        <div className="select-select">
                            <Select placeholder="Items por pagina" options={optionsPerPage} value={valueSelect} onChange={v => {
                                setPageSize(v.value);
                                setValueSelect(v);
                            }} />
                        </div>
                    </div> : null}
                </div>
            </div>
            <div className="wrapper-responsive">
                <div className="table-responsive">
                    <div className="row-responsive header-responsive" style={{ background: props.background, color: props.color }}>{props.columns.map(e => <div className="cell-responsive" style={{ background: e.background != undefined ? e.background : props.background, color: e.color != undefined ? e.color : props.color, textAlign: e.alignTitle != undefined ? e.alignTitle : e.key === "controls" ? 'right' : 'left', minWidth: e.minWidth != undefined ? e.minWidth : 0 }}>{e.text}</div>)}</div>
                    {props.pagination ? filterContainer(paginate()).map((e, i) => getTd(e, i)) : props.data && filterContainer(props.data).map((e, i) => getTd(e, i))}
                </div>
                {props.data.length == 0 ? <div className="no-data" style={{ background: props.backgroundNoData }}><p>{props.textNoData}</p></div> : null}
            </div>

            <div className="container-flex control-container bktable-footer">
                <div className="col-pagination">
                    <div className="container-flex">
                        <div className="pagination-input">
                            {props.pagination ? <div className="container-pagination">
                                <div class="pagination">
                                    <button className={props.paginationClass} disabled={!(pageNumber > 1)} onClick={previusPage} href="#">&laquo;</button>
                                    {createButonIndex()}
                                    <button className={props.paginationClass} disabled={!(pageNumber < pageCont)} onClick={nextPage} href="#">&raquo;</button>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
                <div className="col-exports">
                    {props.exports ? <div className="container-flex">
                        <div className="exports-label">
                            <label>Exportar como:</label>
                        </div>
                        <div className="exports-list">
                            <ul>
                                {props.pdf ? <li onClick={() => onExportsPDF()}>PDF</li> : null}
                                {props.pdf && props.csv ? <li>/</li> : null}
                                {props.csv ? <li onClick={() => onExportsCSV()}>CSV</li> : null}
                                {props.csv && props.xls ? <li>/</li> : null}
                                {props.xls ? <li onClick={() => onExportsXLS()}>XLS</li> : null}
                            </ul>
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}

TableResponsive.defaultProps = {
    data: [],
    background: "#ccc",
    color: "#ffffff",
    textNoData: "Sin datos para mostrar",
    backgroundNoData: '#ccc',
    pagination: true,
    paginationClass: "",
    perPage: 10,
    defaultFilter: 'name',
    pdf: true,
    csv: true,
    xls: true,
    order: false,
    autoHeight: true,
    exports: false
}

export default TableResponsive;

const CheckBoxLabelTable = props => {
    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div>
            <input type="checkbox" className="TableSelectHoursCheck" id={props.id} style={{ display: 'none' }} onChange={change} checked={props.checked} />
            <label className="TableSelectHoursLabel" htmlFor={props.id}>
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <svg width="18" height="18" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M497.36 69.9948C489.828 62.4498 477.607 62.4368 470.075 69.9628L238.582 300.845L155.06 210.132C147.843 202.298 135.641 201.79 127.794 209.006C119.953 216.223 119.451 228.431 126.668 236.272L223.794 341.753C227.351 345.619 232.329 347.864 237.578 347.973C237.719 347.979 237.855 347.979 237.99 347.979C243.091 347.979 247.998 345.953 251.613 342.351L497.322 97.2858C504.873 89.7608 504.886 77.5398 497.36 69.9948Z" fill="#fff" />
                        <path d="M492.703 236.703C482.045 236.703 473.407 245.341 473.407 256C473.407 375.883 375.883 473.407 256 473.407C136.124 473.407 38.593 375.883 38.593 256C38.593 136.124 136.124 38.593 256 38.593C266.658 38.593 275.297 29.955 275.297 19.297C275.297 8.638 266.658 0 256 0C114.84 0 0 114.84 0 256C0 397.154 114.84 512 256 512C397.154 512 512 397.154 512 256C512 245.342 503.362 236.703 492.703 236.703Z" fill="#fff" />
                    </svg>
                </span>
            </label>
        </div>
    )
}

CheckBoxLabelTable.defaultProps = {
    id: Math.random().toString(36).slice(-8),
    checked: false
}

const hh = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
const days = ['lun', 'mar', 'mie', 'jue', 'vie', 'sab', 'dom']
const defaults = {
    lun: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    mar: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    mie: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    jue: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    vie: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    sab: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    dom: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
}

export const TableSelectHours = (props) => {
    const [value, setValue] = useState(defaults);
    const singnal = '#';

    useEffect(() => {
        if (props.data.length == 0) setValue(defaults)
        else setValue(props.data);
    }, [props.data]);

    const onChange = (input, index) => {
        let checked = input.target.checked;
        let i = input.target.id.split(singnal);
        let v = Object.assign([], value);
        v[i[0]][index] = checked;
        setValue(v);
        if (props.onChange != undefined) props.onChange(value);
    }

    return (
        <div className="wrapper-responsive">
            <div className="table-responsive">
                <div className="row-responsive header-responsive" style={{ background: "#F9F9F9", color: "#5A6170" }}>
                    <div className="cell-responsive" style={{ textAlign: 'center', background: "#FF8B03", color: "#fff", fontSize: 12, fontWeight: 400 }}>HORARIO</div>
                    {hh.map(e => <div className="cell-responsive" style={{ textAlign: 'center', width: 160 }}>{e}</div>)}
                </div>
                {days.map(d => {
                    return (
                        <div className="row-responsive">
                            <div className="cell-responsive" data-title="Horario" style={{ textAlign: 'center', background: "#F9F9F9" }}>
                                <label style={{ textTransform: 'uppercase' }}>{d}</label>
                            </div>
                            {hh.map((e, i) => {
                                return (
                                    <div className="cell-responsive" data-title="LUN" style={{ textAlign: 'center', background: "#F9F9F9" }}>
                                        <CheckBoxLabelTable id={d + singnal + e} onChange={v => onChange(v, i)} checked={value[d][i]} />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

TableSelectHours.defaultProps = {
    data: defaults
}


const Select = props => {
    const [val, setVal] = useState(null)
    useEffect(() => {
        if (props.value == undefined) {
            setVal({ value: '', label: '' })
        } else if (typeof props.value === 'string') {
            setVal({ value: props.value, label: props.value })
        } else if (Object.keys(props.value).length === 0) {
            setVal({ value: '', label: '' })
        } else {
            setVal(props.value)
        }
    }, [props.value])

    const change = (val) => {
        setVal(val);
        if (props.onChange != undefined) props.onChange(val);
    }

    return (
        <div className="relative">
            <Seleccion maxMenuHeight={135} className={`menu-outer-top SelectLight form-select ${props.className}`} styles={{ margin: 0, background: props.bg }} options={props.options} placeholder={props.placeholder} value={props.value} onChange={v => change(v)} />
        </div>
    )
}

Select.defaultProps = {
    options: [],
    className: "",
    value: { value: '', label: '' },
    disabled: false,
    bg: "#fff",
    value: null
}