import React, { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';


const Places = (props) => {

    const handleSelect = addrs => {
        geocodeByAddress(addrs)
            .then(results => {
                setAddress(results[0].formatted_address);
                return getLatLng(results[0]);
            })
            .then(latLng => {
                if (props.onChangeCoords != undefined) props.onChangeCoords(latLng);
            })
            .catch(error => console.error('Error', error));
    };


    const setAddress = (str) => {
        if (props.onChangeAddress != undefined) props.onChangeAddress(str);
    }


    return (
        <PlacesAutocomplete
            value={props.value}
            onChange={c => setAddress(c)}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div style={{ position: "relative" }}>

                    <form autocomplete="off">
                        <div className="relative">
                            <label className="inputTextLabel">Dirección</label>
                            <input
                                {...getInputProps({
                                    placeholder: 'Dirección ...',
                                    className: 'location-search-input inputText form-control ',
                                })}
                            />
                        </div>
                    </form>



                    <div className="autocomplete-dropdown-container" style={{ marginBottom: 30, position: "absolute", top: 60, background:"#FAFAFA", zIndex: 9999999 }}>
                        {loading && <div>Cargando...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 10 }
                                : { backgroundColor: '#eee', cursor: 'pointer', padding: 10 };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}

Places.defaultProps = {
    value: ""
}

export default Places;