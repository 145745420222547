import React from 'react';
import { Route, Switch } from 'react-router-dom';
// VIEWS GENERAL
import Dashboard from '../views/MiPanel';
import Perfil from '../views/perfil';
import Agenda from '../views/agenda';
import Bouchers from '../views/boucher'
import Simulate from '../views/simulator'

const Rutas = () =>  (
        <Switch>
                <Route path="/" exact component={Dashboard}></Route>
                <Route path="/perfil" exact component={Perfil}></Route>
                <Route path="/agenda" exact component={Agenda}></Route>
                <Route path="/boucher" exact component={Bouchers}></Route>
                <Route path="/simulate" exact component={Simulate}></Route>        
        </Switch>
  );

export default Rutas; 