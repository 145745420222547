import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './layouts/dynamic';
import { Middleware } from './lib/Router'
import Layout from './layouts/layout/index';
import './index.css';
import { getToken, closeSession } from './lib/Router';
import axios from 'axios';
import Endpoint from './Endpoint';
import { Provider } from 'react-redux';
import { store } from './store'

axios.interceptors.request.use(async (config) => {
	const token = "Bearer " + getToken();
	config.baseURL = Endpoint;
	config.headers.Authorization = token;
	config.headers['Content-Type'] = 'application/json';
	return config;
}, (error) => {
	return Promise.reject(error);
});

axios.interceptors.response.use(response => response, error => {
	if (error.response.status === 403) closeSession();
	return Promise.reject(error.response);
});

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/form" component={Login}></Route>
					<Route path="/" component={() => Middleware(Layout)} />
				</Switch>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
