import React, { useState, useEffect } from "react";
// Componentes
import Main from "../../components/Main";
import Header from '../../components/Header';
import { CardWithTitle, CardWithTitleBlack } from '../../components/card';
import { connect } from '../../store';
import './style.css';
import { Row, Col } from "../../components/grid";
import { Bar } from 'react-chartjs-2';
import Table from '../../components/table'
import "react-circular-progressbar/dist/styles.css";
import { getUserId } from '../../lib/Router'
import axios from 'axios';
import fileDownload from 'js-file-download';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from '../perfil/locale'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from "moment";

var colorArray = [
	'rgba(255, 27, 27, 1)',
	'rgba(255, 160, 0, 1)',
	'rgba(253, 197, 52, 1)',
	'rgba(62, 178, 67, 1)',
	'rgba(73, 195, 157, 1)',
	'rgba(110, 161, 248, 1)',
	'rgba(70, 102, 222, 1)',
	'#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
	'#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
	'#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
	'#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
	'#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
	'#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
	'#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
	'#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
	'#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
	'#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const Boucher = (props) => {
	const [pay, setPay] = useState([])
	const [dateStart, setDateStart] = useState({ from: utils().getToday(), to: null })
	const [columns, setColumns] = useState([
		{ key: 'transaction', text: 'Numero de transacción' },
		{ key: 'createdAt', text: 'Fecha', data: "createdAt", elem: (user) => moment(user).format('DD-MM-YYYY') },
		{ key: 'service', text: 'Servicio' },
		{ key: 'client', text: 'Cliente'},
		{ key: 'ganancia', text: 'Ganancia', elem: data => "$"+data.ganancia },
	])

	const [data, setData] = useState({
		labels: [],
		datasets: [{
			barPercentage: 0.5,
			label: 'Ganancia',
			data: [],
			backgroundColor: colorArray,
			borderColor: colorArray,
			borderWidth: 2,
		}]
	})

	const [options, setOptions] = useState({
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			yAxes: [{
				scaleLabel:{
					display:true,
					labelString: "GANANCIA",
				},
				ticks: {
					// Include a dollar sign in the ticks
					callback: function (value, index, values) {
						return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
					}
				}
			}],
			xAxes: [{
				scaleLabel:{
					display:true,
					labelString: "FECHA",
				},
			}]
		}
	})

	const setDataChart = (d) => {
		setData({
			labels: d.map(e => e.label),
			datasets: [{
				barPercentage: 0.5,
				label: 'Ganancia',
				data: d.map(e => e.data),
				backgroundColor: colorArray,
				borderColor: colorArray,
				borderWidth: 2,
			}]
		})
	}

	const getSalesPay = (last = false, date = dateStart) => {
		axios.post(`/api/sales/pay/${getUserId().userId}`, last ? { last, ...date } : date)
			.then(res => {
				setPay(res.data.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const getChart = (last = false, date = dateStart) => {
		axios.post(`/api/sales/chart/${getUserId().userId}`, last ? { last, ...date } : date)
			.then(res => {
				setDataChart(res.data.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const exportsDataPDF = (dataTable) => {
		axios.post(`/api/exports/pdf/income/${getUserId().userId}`, { data: dataTable }, { responseType: 'blob' })
			.then(res => {
				fileDownload(res.data, 'ganancias.pdf');
			})
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getSalesPay(true);
		getChart(true)
	}, [])

	return (
		<Main>
			<Row>
				<Col>
					<Header title="Mis Ganancias" />
				</Col>
			</Row>
			<Row style={{ display: 'flex', alignItems: "center" }}>
				<Col md={8} className="text-right">
					<p className="m-0 p-0">Seleccionar fechas</p>
				</Col>
				<Col md={4} className="text-right">
					<DatePicker
						value={dateStart}
						onChange={(s) => {
							setDateStart(s);
							if (s.to != null) {
								getChart(false, s);
								getSalesPay(false, s);
							}
						}}
						inputPlaceholder="Selecciona un rango de fecha"
						shouldHighlightWeekends
						calendarClassName="responsive-calendar"
						maximumDate={utils().getToday()}
						locale={localeEs}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<CardWithTitle title="Mis Ganancias" subtitle="Proyección de mis ganacias">
						<div className="pt-4">
							<Bar
								data={data}
								width={200}
								height={260}
								options={options}
							/>
						</div>
					</CardWithTitle>
				</Col>
			</Row>
			<Row>
				<Col>
					<CardWithTitle title="Mis Ganancias">
						<div className="pt-3"></div>
						<Table keyID="_id" onExportsPDF={data => exportsDataPDF(data)} columns={columns} data={pay} background="#FF8B03" paginationClass="table-buttons" xls={false} csv={false} exports={true} />
					</CardWithTitle>
				</Col>
			</Row>
		</Main>
	)
}

export default connect(Boucher);

