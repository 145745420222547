import React, { useState, useEffect } from 'react'
import './style.css'
import { connect } from '../../store'
import Activate from '../activate'
import { PrimaryBtn } from '../../components/buttons';
import { logoWhite, logo } from '../../logos'
import Login from '../login'
import Register from '../register'
import Notifications from '../../components/notify';
import queryString from 'query-string'

const Dynamic = (props) => {
    const [ok, setOk] = useState(false);

    useEffect(() => {
        let q = queryString.parse(props.location.search);
        if (q && q['code'] && q['u']) {
            props.setFormType('active');
            setOk(true);
        } else if (q && q['register']) {
            props.setFormType('register');
            setOk(true);
        } else {
            props.setFormType('login');
            setOk(true);
        }
    }, [])


    return <div className="app">
        <div className={`app-layout`} style={{ borderRadius: '5px' }}>
            {ok ? <div className={`ly ${props.store.form_type}`}>
                <div className="blocked" style={{ width: 800, background: '#fff' }}>
                    <Activate location={props.location} />
                </div>
                <div className="blocked text-center d-flex align-items-center justify-content-center" style={{ width: 600 }}>
                    <Register />
                </div>
                <div className="blocked text-center d-flex align-items-center justify-content-center" style={{ background: '#000', width: 250 }}>
                    <div>
                        {logoWhite}
                        {props.store.form_type === 'login' ? <h2 style={{ fontSize: 28, color: "#fff", letterSpacing: 0.85, marginTop: 30, marginBottom: 20 }}>Bienvenido!</h2> : <h2 style={{ fontSize: 28, color: "#fff", letterSpacing: 0.85, marginTop: 20, marginBottom: 20 }}>Bienvenido!</h2>}
                        {props.store.form_type === 'login' ? <PrimaryBtn className="btn-rounded-border" onClick={() => props.setFormType('register')}>Registrar</PrimaryBtn> : <PrimaryBtn className="btn-rounded-border" onClick={() => props.setFormType('login')}>ENTRAR</PrimaryBtn>}
                    </div>
                </div>
                <div className="blocked d-flex align-items-center justify-content-center" style={{ width: 550 }}>
                    <Login />
                </div>
            </div> : null}
        </div>

        <div className={`app-layout-mobile`} style={{ borderRadius: '5px' }}>
            {props.store.form_type === "active" ? <Activate location={props.location} /> :
                props.store.form_type === "register" ? <div className="text-center w-100 p-4" style={{ background: "#fff" }}>
                    <div style={{ padding: 20 }}>
                        {logo}
                    </div>
                    <Register title="Registro" />

                    <p style={{ marginTop: 20 }}>¿Ya tienes una cuenta? <span onClick={() => props.setFormType('login')} style={{ color: "rgb(250, 88, 5)", fontWeight: 600 }}>iniciar sesión</span></p>
                </div> : <div className="w-100 p-4" style={{ background: "#fff" }}>
                    <div className="text-center" style={{ padding: 20 }}>
                        {logo}
                    </div>
                    <Login />
                    <div className="text-center">
                        <p style={{ marginTop: 20 }}>¿No tienes una cuenta? <span onClick={() => props.setFormType('register')} style={{ color: "rgb(250, 88, 5)", fontWeight: 600 }}>Crea una cuenta</span></p>
                    </div>
                </div>}

        </div>
        <Notifications />
    </div>
}

export default connect(Dynamic);