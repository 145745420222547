import React, { useState, useEffect, Component, Fragment } from 'react';
import axios from 'axios';
import { setToken } from '../../lib/Router';
import './style.css';
import { SecondaryBtn } from '../../components/buttons';
import { connect } from '../../store'
import { useHistory } from "react-router-dom";
import { InputText, InputPassword } from '../../components/input'
import { Row, Col } from '../../components/grid'

const Login = (props) => {
    const history = useHistory();
    const [mail, setMail] = useState("");
    const [username, setUserName] = useState("");
    const [pass, setPass] = useState("");
    const [err, setErr] = useState(false);
    const [vd, setVd] = useState(true)
    const [msg, setMsg] = useState("")
    const [typeAlert, setTypeAlert] = useState(0);

    const login = () => {
        setVd(false);
        setMsg("Iniciando sesion");
        setTypeAlert(2);
        return axios.post('/login', JSON.stringify({ 'email': username, 'password': pass }))
            .then(res => {
                if (res.data.success) {
                    setErr(false)
                    setToken(res.data.token);
                    history.push('/');
                } else {
                    setVd(false);
                    setMsg("Usuario o contraseña incorrecta");
                    setTypeAlert(0);
                }

            })
            .catch(error => {
                switch (error.status) {
                    case 401:
                        setErr(true);
                    default:
                        console.log(error);
                }
                return error;
            })
    }

    const recovery = () => {
        setMsg("Recuperando password");
        setTypeAlert(2);
        setVd(false);
        if (username == null || username.length == 0 || username === "") {
            setVd(false);
            setMsg("Correo no ingresado");
            setTypeAlert(0);
            return false;
        } else {
            axios.post('/recoverypassword', { email: username })
                .then(res => {
                    setVd(false);
                    setMsg("Hemos enviado a tu correo la recuperacion del password");
                    setTypeAlert(1);
                })
                .catch(err => {
                    console.log(0, err)
                    setVd(false);
                    setMsg("Correo no se encuentra registrado");
                    setTypeAlert(0);
                });
        }
    }

    return (
        <Fragment>
            <Row className="form tt-login" style={{ maxWidth: 400 }}>
                <Col md={12} className="text-center">
                    <h2>Inicio Sesión </h2>
                    {err ? <span className="error-label">Los datos ingresados no son correctos</span> : null}
                </Col>
                <Col md={12}>
                    <form className="mx-auto py-4 my-4">
                        <InputText err={err} label="Correo eléctronico" placeholder="correo@dominio.com" onChange={value => setUserName(value)} />
                        <InputPassword err={err} label="Contraseña" placeholder="********" onChange={value => setPass(value)} />
                    </form>
                    <div className="text-center">
                        <p className="recoverPassModalText cursor-pointer mt-4" onClick={() => recovery()}>¿Olvidaste tu contraseña?</p>
                        <br />
                        <br />
                        <SecondaryBtn className="btn-rounded-border" onClick={() => login()}>Entrar</SecondaryBtn>
                        <br />
                        <br />
                    </div>
                    {vd ? null : <div style={{ maxWidth: 400, textAlign: "center" }}>
                        {typeAlert == 0 ?
                            <div className="alert alert-danger m-0" role="alert">{msg}</div> :
                            typeAlert == 1 ? <div className="alert alert-success" role="alert">{msg}</div> :
                                <div className="alert alert-info" role="alert">{msg}</div>}
                    </div>}
                </Col>
            </Row>
        </Fragment>
    )
}

export default connect(Login);