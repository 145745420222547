import React, { useState } from 'react';
import axios from 'axios';
import './style.css';
import { SecondaryBtn } from '../../components/buttons';
import { validaRut } from '../../utils'
import { connect } from '../../store'
import { InputText, InputPhone } from '../../components/input'
import { Row, Col } from '../../components/grid'
import Places from '../../components/places'

const Register = (props) => {
    const [vd, setVd] = useState(true)
    const [msg, setMsg] = useState(null)
    const [typeAlert, setTypeAlert] = useState(0);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [rut, setRut] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [address, setAddress] = useState("");
    const [addressDepto, setAddressDepto] = useState("");
    const [errName, setErrName] = useState(false);
    const [errLastname, setErrLastname] = useState(false);
    const [errRut, setErrRut] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errMail, setErrMail] = useState(false);
    const [errAddress, setErrAddress] = useState(false);
    const [errAddressDepto, setErrAddressDepto] = useState(false);
    const [errMsg, setErrMsg] = useState(undefined);
    const [coords, setCoords] = useState(null);

    const generatePassword = (name, rut) => {
        let s = name;
        let c = s.slice(0, 1);
        let comp = name.slice(1, name.length);
        let r = rut.slice(0, 4);
        return c.toUpperCase() + comp.toLowerCase() + r;
    }


    const send = () => {
        let form = new FormData();
        form.append("role", "expert");
        form.append("name", name);
        form.append("lastname", lastname);
        form.append("rut", rut);
        form.append("address", address);
        form.append("address_other", addressDepto);
        form.append("email", mail);
        form.append("phone", phone);
        form.append("lat", coords.lat);
        form.append("lon", coords.lng);
        form.append("password", generatePassword(name, rut));

        setVd(false);
        setMsg("Registrando usuario");
        setTypeAlert(2);
        return axios.post('/register', form)
            .then(res => {
                if (res.data.success) {
                    setMsg("Enviando codigo de verificación a su correo");
                    setTypeAlert(2);
                    setTimeout(() => {
                        setMsg("Codigo enviado enviado a su correo");
                        setTypeAlert(1);
                    }, 2500);
                    setTimeout(() => {
                        props.setUserIdActivate(res.data.data._id);
                        props.setFormType('active');
                    }, 4000);
                } else {
                    if (res.data.err.code == 0) {
                        setMsg("Rut asociado a una cuenta existente");
                        setTypeAlert(0);
                    } else {
                        setMsg("Correo asociado a una cuenta existente");
                        setTypeAlert(0);
                    }
                }
            })
            .catch(err => {
                setMsg(err.toString());
                setTypeAlert(0);
            })
    }

    const register = () => {
        if (name === "" || name.length == 0) {
            setErrMsg(undefined)
            return setErrName(true);
        }
        else setErrName(false);

        if (lastname === "" || lastname.length == 0) {
            setErrMsg(undefined)
            return setErrLastname(true);
        }
        else setErrLastname(false);

        if (rut == null || rut.length == 0 || rut === "") {
            setErrMsg(undefined)
            return setErrRut(true);
        } else setErrRut(false);

        if (!validaRut(rut)) {
            setErrMsg('Rut incorrecto')
            return setErrRut(true);
        }
        else setErrRut(false);

        if (phone == null || phone.length == 0 || phone === '') {
            setErrMsg(undefined)
            return setErrPhone(true);
        }
        else setErrPhone(false);

        const rt = /^(\+?56)?(\s?)(0?9)(\s?)[987654321]\d{7}$/;
        if (!rt.test(phone)) {
            setErrMsg('Teléfono incorrecto')
            return setErrPhone(true);
        }
        else setErrPhone(false);

        if (mail == null || mail.length == 0 || mail === '') {
            setErrMsg(undefined)
            return setErrMail(true);
        }
        else setErrMail(false);

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(mail)) {
            setErrMsg('Correo incorrecto')
            return setErrMail(true);
        }
        else setErrMail(false);

        if (address === "" || address.length == 0) {
            setErrMsg(undefined)
            return setErrAddress(true);
        }
        else setErrAddress(false);

        send();
    }

    const renderForm = () => {
        return (
            <Row className="form tt-register" style={{ paddingLeft: 50, width: 520 }}>
                <Col md={12}>
                    <h2>{props.title}</h2>
                </Col>
                <Col md={12} className="p-0">
                    <form className="mx-auto pt-4">
                        <div className="form-row d-flex align-items-center justify-content-center text-left">
                            <Row className="w-100">
                                <Col md={6}>
                                    <InputText pattern={"[a-z áéíóú]"} message={errMsg} err={errName} label="Nombre" placeholder="Jhon" onChange={value => setName(value)} />
                                </Col>
                                <Col md={6}>
                                    <InputText pattern={"[a-z áéíóú]"} message={errMsg} err={errLastname} label="Apellido" placeholder="Dhere" onChange={value => setLastname(value)} />
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <Col md={6}>
                                    <InputText message={errMsg} err={errRut} label="Rut" placeholder="(11111111-1)" onChange={value => setRut(value)} />
                                </Col>
                                <Col md={6}>
                                    <InputPhone message={errMsg} err={errPhone} label="Teléfono" onChange={value => setPhone(value)} />
                                </Col>
                            </Row>

                            <Row className="w-100">
                                <Col md={12}>
                                    <Places value={address} onChangeCoords={setCoords} onChangeAddress={setAddress}/>
                                </Col>
                                <Col md={6}>
                                    <InputText pattern={"[A-Za-z0-9 áéíóú]"} message={errMsg} err={errAddressDepto} label="Depto/Block" placeholder="Ej: 204" onChange={value => setAddressDepto(value)} />
                                </Col>
                                <Col md={6}>
                                    <InputText message={errMsg} err={errMail} label="Correo Eléctronico" placeholder="mail@dominio.com" onChange={value => setMail(value)} />
                                </Col>
                            </Row>
                        </div>
                    </form>
                </Col>
                <Col md={12}>
                    <Row>
                        <Col md={12} className="text-center">
                            <br />
                            <br />
                            <div className="mt-2">
                                <SecondaryBtn className="btn-rounded-border" onClick={() => register()}>Registrar</SecondaryBtn>
                            </div>
                        </Col>
                        <Col md={12}>
                            <br />
                            <a style={{ color: "#000" }} target="_blank" href="/terms_and_conditions.pdf">Al registrar acepta términos y condiciones</a>
                        </Col>
                    </Row>
                    {vd ? null : <div>
                        {typeAlert == 0 ?
                            <div className="alert alert-danger" role="alert">{msg}</div> :
                            typeAlert == 1 ? <div className="alert alert-success mb-0" role="alert">{msg}</div> :
                                <div className="alert alert-info" role="alert">{msg}</div>}
                    </div>}
                </Col>
            </Row>
        )
    }

    return renderForm();
}

Register.defaultProps = {
    title: "Registrarse"
}


export default connect(Register);


