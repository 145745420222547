import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios';
import { getUserId, setToken } from '../../lib/Router'
import { CardWithTitle } from '../../components/card';
import { connect } from '../../store';
import { getUrl, loadData } from '../../utils'
import { SecondaryBtn } from '../../components/buttons';
import { Row, Col } from "../../components/grid";
import { DocuFile, ServicePill, ModalAgreggateService } from "../../components/input";
import './style.css';

const TabFilesAndServices = (props) => {
    let user = getUserId();
    const [selected, setSelected] = useState([]);
    const [antecedentes, setAntecedentes] = useState(getUrl(user.user.antecedentes && user.user.antecedentes.url));
    const [estudios, setEstudios] = useState(getUrl(user.user.estudios && user.user.estudios.url));
    const [ci, setCi] = useState(getUrl(user.user.ci && user.user.ci.url));
    const [cv, setCv] = useState(getUrl(user.user.cv && user.user.cv.url));
    const [recomendacion1, setRecomendacion1] = useState(getUrl(user.user.recomendacion1 && user.user.recomendacion1.url))
    const [recomendacion2, setRecomendacion2] = useState(getUrl(user.user.recomendacion2 && user.user.recomendacion2.url))

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    useEffect(() => {
        axios.get(`/api/user/myservices/${user.userId}`)
            .then(res => {
                let r = res.data.data.map(e => {
                    e['value'] = e.name;
                    e['label'] = e.name;
                    return e;
                })
                setSelected(r);
            })
            .catch(err => console.log(err));
    }, [])

    const saveServices = (service) => {
        axios.post(`/api/user/add/service/${user.userId}`, { services: service.map(e => e._id) })
            .then(response => {
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
                loadData(`/api/user/myservices/${user.userId}`, props.setMyService, { value: '_id', label: 'name' })
                loadData('/api/catservice', props.setCatService, { value: '_id', label: 'name' });
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }



    const uploadFileAntecedentes = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/antecedentes/${user.userId}`, form)
            .then(res => {
                setToken(res.data.token)
                setAntecedentes(getUrl(user.user.antecedentes && user.user.antecedentes.url))
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => {
                console.log(err);
                props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" })
            })
    }

    const uploadFileCI = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/ci/${user.userId}`, form)
            .then(res => {
                setCi(getUrl(user.user.ci && user.user.ci.url))
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    const uploadFileEstudios = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/estudios/${user.userId}`, form)
            .then(res => {
                setEstudios(getUrl(user.user.estudios && user.user.estudios.url))
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    const uploadFileCV = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/cv/${user.userId}`, form)
            .then(res => {
                setCv(getUrl(user.user.cv && user.user.cv.url))
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    const uploadFileRecomendacion1 = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/recomendacion1/${user.userId}`, form)
            .then(res => {
                setRecomendacion1(getUrl(user.user.recomendacion1 && user.user.recomendacion1.url))
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    const uploadFileRecomendacion2 = (file) => {
        let form = new FormData();
        if (file != null) form.append("doc", file, file.name);
        axios.post(`/api/user/file/recomendacion2/${user.userId}`, form)
            .then(res => {
                setRecomendacion2(getUrl(user.user.recomendacion2 && user.user.recomendacion2.url))
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    const removeFileStatic = (type) => {
        axios.put(`/api/user/removefile/${type}/${user.userId}`, {})
            .then(res => {
                switch (type) {
                    case "cv":
                        setCv(null);
                        break;
                    case "ci":
                        setCi(null);
                        break;
                    case "estudios":
                        setEstudios(null);
                        break;
                    case "antecedentes":
                        setAntecedentes(null);
                        break;
                    case "recomendacion1":
                        setRecomendacion1(null);
                        break;
                    case "recomendacion2":
                        setRecomendacion2(null);
                        break;
                }
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => {
                console.log(err);
                props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" })
            })
    }

    const removePill = (id) => {
        let a = Object.assign([], selected);
        a.splice(a.findIndex(e => e._id === id), 1);
        setSelected(a);
    }

    return (
        <Row>
            <Col xs={12} md={6}>
                <CardWithTitle title="Sube tus archivos" subtitle="Puedes subir PDF, JPG, PNG">
                    <Row className="mt-40">
                        <Col xs={6}><DocuFile name="Certificado de antecedentes" src={antecedentes} onChange={uploadFileAntecedentes} onRemove={v => removeFileStatic('antecedentes')} /></Col>
                        <Col xs={6}><DocuFile name="Copia cédula de identidad" src={ci} onChange={uploadFileCI} onRemove={v => removeFileStatic('ci')} /></Col>
                        <Col xs={6}><DocuFile name="Currículum vitae" src={cv} onChange={uploadFileCV} onRemove={v => removeFileStatic('cv')} /></Col>
                        <Col xs={6}><DocuFile name="Certificado de estudios" src={estudios} onChange={uploadFileEstudios} onRemove={v => removeFileStatic('estudios')} /></Col>
                        <Col xs={6}><DocuFile name="Otros documentos 1" src={recomendacion1} onChange={uploadFileRecomendacion1} onRemove={v => removeFileStatic('recomendacion1')} /></Col>
                        <Col xs={6}><DocuFile name="Otros documentos 2" src={recomendacion2} onChange={uploadFileRecomendacion2} onRemove={v => removeFileStatic('recomendacion2')} /></Col>
                    </Row>
                </CardWithTitle>
            </Col>
            <Col xs={12} md={6}>
                <CardWithTitle title="Selecciona hasta 3 categorias" subtitle="Puedes eliminar o agregar">
                    <div className="servicesPillContainer text-center mt-40">
                        <Row className="justify-content-around p-4">
                            {selected.map(e => {
                                return (
                                    <Col md={4}>
                                        <ServicePill name={e.label} color={e.color} onRemove={() => removePill(e._id)} />
                                    </Col>
                                )
                            })}
                            {selected.length == 0 ? <div style={{ height: 40, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ margin: 0 }}>No hay categorias seleccionadas</p></div> : null}
                        </Row>
                    </div>
                    <div className="text-right">
                        <ModalAgreggateService options={props.service} values={selected} onClick={v => setSelected(v)} />
                        <SecondaryBtn onClick={() => saveServices(selected)}>Guardar</SecondaryBtn>
                    </div>
                </CardWithTitle>
            </Col>
        </Row>
    )
}

TabFilesAndServices.defaultProps = {
    service: [],
    selected: []
}

export default connect(TabFilesAndServices)