import React, { useEffect, useState } from "react";
// Componentes
import axios from 'axios'
import moment from 'moment'
import Main from "../../components/Main";
import { connect } from '../../store';
import Calendar from '../../components/calendar'
import { FlatCard } from '../../components/card'
import { ModalCalendar } from '../../components/modal'
import { Row, Col } from '../../components/grid'
import { loadData } from '../../utils'
import { getUserId } from '../../lib/Router'
import './style.css';

moment.lang('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});


let icn = 28;

const icon1 = <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8848 35.4595C27.5497 35.4595 35.3848 27.6245 35.3848 17.9595C35.3848 8.29449 27.5497 0.459473 17.8848 0.459473C8.21978 0.459473 0.384766 8.29449 0.384766 17.9595C0.384766 27.6245 8.21978 35.4595 17.8848 35.4595Z" fill="#FDAD34" />
    <g clip-path="url(#clip0)">
        <path d="M17.3059 22.1073C16.7508 22.6625 15.8501 22.6625 15.2952 22.1073L12.18 18.9921C11.6249 18.4373 11.6249 17.5366 12.18 16.9817C12.7348 16.4266 13.6355 16.4266 14.1907 16.9817L16.0467 18.8375C16.1868 18.9774 16.4143 18.9774 16.5547 18.8375L21.5804 13.8118C22.1352 13.2567 23.0359 13.2567 23.591 13.8118C23.8576 14.0784 24.0073 14.4401 24.0073 14.817C24.0073 15.194 23.8576 15.5557 23.591 15.8222L17.3059 22.1073Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0">
            <rect width="12.2437" height="12.2434" fill="white" transform="translate(11.7637 11.8379)" />
        </clipPath>
    </defs>
</svg>

const icon2 = <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8848 35.4595C27.5497 35.4595 35.3848 27.6245 35.3848 17.9595C35.3848 8.29449 27.5497 0.459473 17.8848 0.459473C8.21978 0.459473 0.384766 8.29449 0.384766 17.9595C0.384766 27.6245 8.21978 35.4595 17.8848 35.4595Z" fill="black" />
    <path d="M20.0672 17.9329L23.5767 14.4234C24.1743 13.8258 24.1743 12.8569 23.5767 12.2593C22.979 11.6616 22.0102 11.6616 21.4125 12.2593L17.9031 15.7687L14.3936 12.2593C13.7967 11.6616 12.8264 11.6616 12.2295 12.2593C11.6318 12.8569 11.6318 13.8258 12.2295 14.4234L15.739 17.9329L12.2295 21.4423C11.6318 22.04 11.6318 23.0088 12.2295 23.6065C12.5279 23.9057 12.9198 24.0549 13.3116 24.0549C13.7034 24.0549 14.0952 23.9057 14.3936 23.6065L17.9031 20.097L21.4125 23.6065C21.7118 23.9057 22.1028 24.0549 22.4946 24.0549C22.8864 24.0549 23.2775 23.9057 23.5767 23.6065C24.1743 23.0088 24.1743 22.04 23.5767 21.4423L20.0672 17.9329Z" fill="white" />
</svg>


const Agenda = (props) => {
    const [modal, setModal] = useState(false);
    const [ev, setEv] = useState({});

    const accepEvent = (id) => {
        axios.get(`/api/calendar/accept/${id}`)
            .then(response => { })
            .catch(err => console.log(err))
    }

    const rejectEvent = (id) => {
        axios.get(`/api/calendar/reject/${id}`)
            .then(response => { })
            .catch(err => console.log(err))
    }

    const renderEventContent = (event, props, all) => {
        if (all.view.type === "dayGridMonth") {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                        <div style={{ background: all.backgroundColor, borderColor: all.borderColor, width: '8px', height: '8px', borderRadius: 100, marginRight: 6, marginLeft: 6 }}></div>
                        <p style={{ margin: 0, fontSize: 14 }}><b>{event.title}</b> {props.hour}</p>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <p className={`agenda-title ${props.ok ? 'tachado' : ''}`}><b>{event.title}</b> {props.hour}</p>
                    {/* <p className={`agenda-date ${props.ok ? 'tachado' : ''}`}>{moment(event.startStr).format('LL')} {props.hour}</p> */}
                </>
            )
        }

    }

    const currency = function (number) {
        return new Intl.NumberFormat('es-CL', { style: 'decimal', currency: 'CLP' }).format(number);
    };

    const eventClick = (event, props, all) => {
        setEv({ name: props.service.detail.name, date: moment(event.startStr).format('LLL'), address: props.address, num: props.num, depto: props.depto, comune: props.comune, clientName: props.clientName, price: currency(props.pricing.tarifa - props.pricing.feeProveedor) })
        setModal(true);
    }

    useEffect(() => {
    }, [props.store.shedule_events])

    useEffect(() => {
        let userId = getUserId().userId
        loadData(`/api/calendar/events/all/${userId}`, props.setSheduleEvents, null) //res => console.log("--->>",res)
        loadData(`/api/calendar/events/pendings/${userId}`, props.setShedulePendings, null); // res => console.log("pend --->>",res)
    }, [])

    return (
        <Main id="Agenda">
            <div className="row">
                <div className="col-12">
                    <FlatCard style={{ height: 'calc(100vh - 100px)' }}>
                        <Calendar height="calc(100vh - 140px)" renderEvent={renderEventContent} events={props.store.shedule_events} eventClick={eventClick} />
                    </FlatCard>
                </div>
            </div>
            <ModalCalendar open={modal} width="750px" onClose={setModal} accept={false} cancel={true} textCancel="Aceptar">
                <div>
                    <Row>
                        <Col md={12}>
                            <br /><br />
                        </Col>
                        <Col md={12} className="text-left">
                            <Row>
                                <Col md={1}></Col>
                                <Col md={1}>
                                    <svg style={{ marginTop: -8 }} width={icn} height={icn} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8848 35.4595C27.5497 35.4595 35.3848 27.6245 35.3848 17.9595C35.3848 8.29449 27.5497 0.459473 17.8848 0.459473C8.21978 0.459473 0.384766 8.29449 0.384766 17.9595C0.384766 27.6245 8.21978 35.4595 17.8848 35.4595Z" fill="#FF7C03" />
                                        <g clip-path="url(#clip0)">
                                            <path d="M17.3059 22.1073C16.7508 22.6625 15.8501 22.6625 15.2952 22.1073L12.18 18.9921C11.6249 18.4373 11.6249 17.5366 12.18 16.9817C12.7348 16.4266 13.6355 16.4266 14.1907 16.9817L16.0467 18.8375C16.1868 18.9774 16.4143 18.9774 16.5547 18.8375L21.5804 13.8118C22.1352 13.2567 23.0359 13.2567 23.591 13.8118C23.8576 14.0784 24.0073 14.4401 24.0073 14.817C24.0073 15.194 23.8576 15.5557 23.591 15.8222L17.3059 22.1073Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="12.2437" height="12.2434" fill="white" transform="translate(11.7637 11.8379)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </Col>
                                <Col md={9}>
                                    <span style={{ position: 'absolute', top: -20 }}>Servicio:</span>
                                    <h4 style={{ padding: 0, marginTop: -3 }}>{ev.name}</h4>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <br /><br />
                        </Col>
                        <Col md={12} className="text-left">
                            <Row>
                                <Col md={1}></Col>
                                <Col md={1}>
                                    <svg style={{ marginTop: -8 }} width={icn} height={icn} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8848 35.4595C27.5497 35.4595 35.3848 27.6245 35.3848 17.9595C35.3848 8.29449 27.5497 0.459473 17.8848 0.459473C8.21978 0.459473 0.384766 8.29449 0.384766 17.9595C0.384766 27.6245 8.21978 35.4595 17.8848 35.4595Z" fill="#FF7C03" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5168 10.7915C14.2306 10.7915 13.9986 11.0235 13.9986 11.3097V12.1731H11.7523C11.2761 12.1731 10.8887 12.5605 10.8887 13.0367V24.2637C10.8887 24.7399 11.2761 25.1273 11.7523 25.1273H24.0156C24.4918 25.1273 24.8792 24.7399 24.8792 24.2637V13.0367C24.8792 12.5605 24.4918 12.1731 24.0156 12.1731H21.9436V11.3097C21.9436 11.0235 21.7116 10.7915 21.4254 10.7915C21.1392 10.7915 20.9073 11.0235 20.9073 11.3097V12.1731H15.0349V11.3097C15.0349 11.0235 14.803 10.7915 14.5168 10.7915ZM20.9073 13.2094H15.0349V14.0732C15.0349 14.3594 14.803 14.5914 14.5168 14.5914C14.2306 14.5914 13.9986 14.3594 13.9986 14.0732V13.2094H11.925V15.6279H23.8429V13.2094H21.9436V14.0732C21.9436 14.3594 21.7116 14.5914 21.4254 14.5914C21.1392 14.5914 20.9073 14.3594 20.9073 14.0732V13.2094ZM11.925 24.091H23.8429H23.8429V16.6642H11.925V24.091Z" fill="white" />
                                    </svg>
                                </Col>
                                <Col md={9}>
                                    <span style={{ position: 'absolute', top: -20 }}>Fecha:</span>
                                    <h4 style={{ padding: 0, marginTop: -3 }}>{ev.date}</h4>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <br /><br />
                        </Col>
                        <Col md={12} className="text-left">
                            <Row>
                                <Col md={1}></Col>
                                <Col md={1}>
                                    <svg style={{ marginTop: -8 }} width={icn} height={icn} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1621 35.4595C27.8271 35.4595 35.6621 27.6245 35.6621 17.9595C35.6621 8.29449 27.8271 0.459473 18.1621 0.459473C8.49713 0.459473 0.662109 8.29449 0.662109 17.9595C0.662109 27.6245 8.49713 35.4595 18.1621 35.4595Z" fill="#FF7C03" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6611 16.6978C11.6611 13.2579 14.5775 10.4595 18.1623 10.4595C21.7473 10.4595 24.6635 13.2579 24.6635 16.6978C24.6635 21.3662 18.6985 25.2157 18.4444 25.3773C18.3584 25.432 18.2603 25.4595 18.1623 25.4595C18.0642 25.4595 17.9662 25.4321 17.8802 25.3773C17.6261 25.2157 11.6611 21.3662 11.6611 16.6978ZM12.7125 16.6978C12.7125 20.2626 17.0075 23.4951 18.1621 24.2991C19.3162 23.4941 23.6121 20.2568 23.6121 16.6978C23.6121 13.8376 21.1674 11.5109 18.1623 11.5109C15.1572 11.5109 12.7125 13.8376 12.7125 16.6978ZM15.2008 16.2774C15.2008 14.6444 16.5292 13.316 18.1622 13.316C19.7952 13.316 21.1237 14.6446 21.1237 16.2774C21.1237 17.9102 19.7952 19.2387 18.1622 19.2387C16.5292 19.2387 15.2008 17.9102 15.2008 16.2774ZM16.2522 16.2773C16.2522 17.3304 17.1091 18.1871 18.1622 18.1871C19.2154 18.1871 20.0723 17.3304 20.0723 16.2773C20.0723 15.2239 19.2154 14.3672 18.1622 14.3672C17.1091 14.3672 16.2522 15.2241 16.2522 16.2773Z" fill="white" />
                                    </svg>
                                </Col>
                                <Col md={9}>
                                    <span style={{ position: 'absolute', top: -20 }}>Dirección:</span>
                                    <h4 style={{ padding: 0, marginTop: -3 }}>{ev.address}</h4>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <br /><br />
                        </Col>
                        <Col md={12} className="text-left">
                            <Row>
                                <Col md={1}></Col>
                                <Col md={1}>
                                    <svg style={{ marginTop: -8 }} width={icn} height={icn} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4395 35.4595C28.1044 35.4595 35.9395 27.6245 35.9395 17.9595C35.9395 8.29449 28.1044 0.459473 18.4395 0.459473C8.77447 0.459473 0.939453 8.29449 0.939453 17.9595C0.939453 27.6245 8.77447 35.4595 18.4395 35.4595Z" fill="#FF7C03" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2918 14.779C15.2918 13.0097 16.6447 11.6753 18.439 11.6753C20.2332 11.6753 21.5862 13.0097 21.5862 14.7792C21.5862 16.6002 20.1743 18.0816 18.439 18.0816C16.7036 18.0816 15.2918 16.6002 15.2918 14.779ZM16.2407 14.7792C16.2407 16.0944 17.2267 17.1644 18.439 17.1644C19.6512 17.1644 20.6373 16.0944 20.6373 14.7792C20.6373 13.5326 19.6923 12.5927 18.439 12.5927C17.1652 12.5927 16.2407 13.5122 16.2407 14.7792ZM12.3818 23.785C12.3818 20.5411 14.533 18.7546 18.439 18.7546C22.345 18.7546 24.4962 20.5413 24.4962 23.785C24.4962 24.0384 24.2838 24.2437 24.0217 24.2437H12.8563C12.5942 24.2437 12.3818 24.0384 12.3818 23.785ZM18.439 19.672C15.245 19.672 13.5346 20.9009 13.348 23.3263H23.5302C23.3435 20.9009 21.633 19.672 18.439 19.672Z" fill="white" />
                                    </svg>
                                </Col>
                                <Col md={9}>
                                    <span style={{ position: 'absolute', top: -20 }}>Cliente:</span>
                                    <h4 style={{ padding: 0, marginTop: -3 }}>{ev.clientName}</h4>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <br /><br />
                        </Col>
                        <Col md={12} className="text-left">
                            <Row>
                                <Col md={1}></Col>
                                <Col md={1}>
                                    <svg style={{ marginTop: -8 }} width={icn} height={icn} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4395 35.4595C28.1044 35.4595 35.9395 27.6245 35.9395 17.9595C35.9395 8.29449 28.1044 0.459473 18.4395 0.459473C8.77447 0.459473 0.939453 8.29449 0.939453 17.9595C0.939453 27.6245 8.77447 35.4595 18.4395 35.4595Z" fill="#FF7C03" />
                                        <path d="M18.4395 17.2095C17.1857 17.2095 16.1657 16.3683 16.1657 15.3345C16.1657 14.3006 17.1857 13.4595 18.4395 13.4595C19.6932 13.4595 20.7132 14.3006 20.7132 15.3345C20.7132 15.7487 21.1204 16.0845 21.6227 16.0845C22.1251 16.0845 22.5322 15.7487 22.5322 15.3345C22.5322 13.7313 21.1692 12.3866 19.349 12.0445V11.2095C19.349 10.7953 18.9418 10.4595 18.4395 10.4595C17.9371 10.4595 17.5299 10.7953 17.5299 11.2095V12.0445C15.7097 12.3866 14.3467 13.7313 14.3467 15.3345C14.3467 17.1955 16.1827 18.7095 18.4395 18.7095C19.6932 18.7095 20.7132 19.5506 20.7132 20.5845C20.7132 21.6183 19.6932 22.4595 18.4395 22.4595C17.1857 22.4595 16.1657 21.6183 16.1657 20.5845C16.1657 20.1703 15.7585 19.8345 15.2562 19.8345C14.7539 19.8345 14.3467 20.1703 14.3467 20.5845C14.3467 22.1876 15.7097 23.5323 17.5299 23.8744V24.7095C17.5299 25.1237 17.9371 25.4595 18.4395 25.4595C18.9418 25.4595 19.349 25.1237 19.349 24.7095V23.8744C21.1692 23.5323 22.5322 22.1876 22.5322 20.5845C22.5322 18.7235 20.6962 17.2095 18.4395 17.2095Z" fill="white" />
                                    </svg>
                                </Col>
                                <Col md={9}>
                                    <span style={{ position: 'absolute', top: -20 }}>Ganancia:</span>
                                    <h4 style={{ padding: 0, marginTop: -3 }}>${ev.price}</h4>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </ModalCalendar>
            <ModalCalendar width="900px" open={props.store.shedule_pendings.length != 0 ? true : false} onClose={setModal}>
                <div className="text-center">
                    <Row>
                        <Col md={12}>
                            <h1 style={{ marginBottom: 15 }}>Solicitudes pendientes</h1>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={12} style={{ height: 400, overflowX: 'auto' }}>
                                    <table className="table">
                                        <thead>
                                            <th>Servicio</th>
                                            <th>Fecha</th>
                                            <th>Cliente</th>
                                            <th>Dirección</th>
                                            <th>Ganancia</th>
                                            <th>Acción</th>
                                        </thead>
                                        <tbody>
                                            {props.store.shedule_pendings.map((e) => {
                                                return (
                                                    <tr>
                                                        <td style={{ verticalAlign: "middle" }}>{e.service.detail.name}</td>
                                                        <td style={{ verticalAlign: "middle" }}>{e.dateStr} {e.hour}</td>
                                                        <td style={{ verticalAlign: "middle" }}>{e.clientName}</td>
                                                        <td style={{
                                                            whiteSpace: "nowrap",
                                                            maxWidth: 300,
                                                            overflow: 'hidden',
                                                            textOverflow: "ellipsis",
                                                            verticalAlign: "middle"
                                                        }} title={e.address}>{e.address}</td>
                                                        <td style={{ verticalAlign: "middle" }}>${currency(e.pricing.tarifa - e.pricing.feeProveedor)}</td>
                                                        <td style={{ verticalAlign: "middle" }}>
                                                            <button title="Rechazar" style={{ margin: "0 2px", padding: 0, border: 0, background: "transparent" }} onClick={() => rejectEvent(e._id)}>{icon2}</button>
                                                            <button title="Aceptar" style={{ margin: "0 2px", padding: 0, border: 0, background: "transparent" }} onClick={() => accepEvent(e._id)}>{icon1}</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </ModalCalendar>
        </Main>
    )
}

export default connect(Agenda);