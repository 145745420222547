import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { connect } from '../../store'
import './style.css'

const Calendar = props => {
    const calendar = useRef();
    const [events, setEvents] = useState([]);

    const handleDateClick = (event) => {
        if (props.handleDateClick != undefined) props.handleDateClick(event.event, event.event.extendedProps, event);
    }

    const eventReceive = (event) => {
        if (props.eventReceive != undefined) props.eventReceive(event.event, event.event.extendedProps, event);
    }

    const eventClick = (event) => {
        if (props.eventClick != undefined) props.eventClick(event.event, event.event.extendedProps, event);
    }

    const dropEvent = (event) => {
        if (props.dropEvent != undefined) props.dropEvent(event.event, event.event.extendedProps, event);
    }

    const renderEvent = (event) => {
        if (props.renderEvent != undefined) {
            return props.renderEvent(event.event, event.event.extendedProps, event);
        } else {
            return (
                <>
                    <b>{event.timeText}</b>
                    <i>{event.event.title}</i>
                </>
            )
        }
    }

    useEffect(() => {
        setEvents(props.events);
    }, [props.events])

    useEffect(() => {
        const drag = document.getElementById(props.drag);
        if (drag != undefined) {
            new Draggable(drag, {
                itemSelector: ".event",
                eventData: function (eventEl) {
                    let title = eventEl.getAttribute("title");
                    let id = eventEl.getAttribute("id");
                    return {
                        title: title,
                        groupId: Math.random().toString(36).slice(-8),
                        id: Math.random().toString(36).slice(-8),
                        sessionId: id,
                        startTime: "00:00",
                        create: false,
                    };
                }
            });
        }

        calendar.current.getApi().setOption('height', props.height)

    }, [])

    return (
        <FullCalendar
            select={(start, end, allDay) => {  }}
            weekends={props.weekends}
            eventRender={(info) => console.log(info)}
            eventLimit={props.eventLimit}
            headerToolbar={props.headers}
            style={{ zIndex: "0" }}
            customButtons={props.buttons}
            dateClick={handleDateClick}
            eventReceive={eventReceive}
            eventClick={eventClick}
            editable={props.editable}
            droppable={props.droppable}
            drop={dropEvent}
            events={events}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            locale={esLocale}
            eventContent={renderEvent}
            eventDurationEditable={true}
            timeZone="local"
            hiddenDays={props.hiddenDays}
            nowIndicator={true}
            eventOrder="start,hour"
            expandRows={true}
            aspectRatio={1.09}
            // hiddenDays={[ 1, 3, 5 ]}
            // dayHeaders={false}
            slotDuration={'01:00:00'}
            // showNonCurrentDates={false}
            fixedWeekCount={false}
            dayHeaderFormat={{ weekday: 'short' }}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }}
            ref={calendar} />
    )
}

Calendar.defaultProps = {
    hiddenDays: [],
    editable: true,
    droppable: true,
    weekends: true,
    eventLimit: false,
    drag: "dm294rvcmccjfveccewcfmc2ewocx3e",
    events: [],
    headers: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'prev today next'
    },
    buttons: {
        add: {
            text: 'Nuevo',
            click: function () { },
        }
    },
    height: 'calc(100vh - 20px)'
}

export default connect(Calendar);
