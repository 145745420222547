export const IconCamera = <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.2359" cy="14.1666" r="13.2754" fill="#B5B8C9" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6439 10.6422H18.9497C19.767 10.6422 20.431 11.3062 20.431 12.123V17.2531C20.431 18.0699 19.767 18.7339 18.9497 18.7339H8.6368C7.81955 18.7339 7.15552 18.0699 7.15552 17.2531V12.123C7.15552 11.3062 7.81955 10.6422 8.6368 10.6422H9.94261C10.0048 10.6422 10.0584 10.6002 10.0741 10.5395L10.3361 9.72466C10.465 9.21336 10.9222 8.85632 11.4487 8.85632H16.1378C16.6643 8.85632 17.1215 9.21336 17.2499 9.72417L17.5124 10.54C17.5281 10.6002 17.5817 10.6422 17.6439 10.6422ZM10.8063 14.1823C10.8063 15.8558 12.1672 17.2167 13.8407 17.2167C15.5141 17.2167 16.8751 15.8558 16.8751 14.1823C16.8751 12.5088 15.5141 11.1479 13.8407 11.1479C12.1672 11.1479 10.8063 12.5088 10.8063 14.1823ZM17.3808 12.6651C17.3808 12.9443 17.6074 13.1709 17.8865 13.1709C18.1657 13.1709 18.3923 12.9443 18.3923 12.6651C18.3923 12.3859 18.1657 12.1594 17.8865 12.1594C17.6074 12.1594 17.3808 12.386 17.3808 12.6651ZM11.8177 14.1823C11.8177 13.0667 12.725 12.1594 13.8406 12.1594C14.9563 12.1594 15.8636 13.0667 15.8636 14.1823C15.8636 15.2979 14.9563 16.2052 13.8406 16.2052C12.725 16.2052 11.8177 15.2979 11.8177 14.1823Z" fill="#5A6170" />
</svg>

export const startActive = <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.86409 0.0497932L11.4444 5.27746L17.2128 6.11609L13.0384 10.1844L14.0247 15.9301L8.86409 13.2169L3.70351 15.9301L4.68973 10.1844L0.515381 6.11609L6.2838 5.27746L8.86409 0.0497932Z" fill="#FF7C03" />
</svg>

export const startDisabled = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9789 5.77226L10.7188 5.73445L10.6025 5.49877L8.47054 1.17947L6.3386 5.49877L6.22227 5.73445L5.96218 5.77226L1.19634 6.46513L4.64516 9.82638L4.83346 10.0099L4.78897 10.269L3.97417 15.016L8.23786 12.7744L8.47054 12.652L8.70321 12.7744L12.9669 15.016L12.1521 10.269L12.1076 10.0099L12.2959 9.82638L15.7447 6.46513L10.9789 5.77226Z" fill="white" stroke="#FDAD34" />
</svg>