import React, { useState } from 'react';
import './style.css';
import { connect } from '../../store';

const Notification = (props) => {

    return (
        <div style={{ position: 'fixed', top: 5, right: 5 }}>
            {
                props.store.notifications.map((e,i) => {
                    if (e.control === "default") setTimeout(() => props.notifyRemove(e), e.time);
                    switch (e.type) {
                        case 'error':
                            return <div style={{ marginBottom: 5 }} className="notify danger" key={i}>
                                <div className="notify-icon">
                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.6131 4.39105C19.7639 -1.45819 10.2434 -1.45819 4.39416 4.39105C1.56078 7.22555 0 10.9933 0 15.0005C0 19.0076 1.56078 22.7754 4.39416 25.6088C7.31937 28.534 11.1615 29.996 15.0036 29.996C18.8457 29.996 22.6879 28.534 25.613 25.6088C31.4623 19.7596 31.4623 10.2414 25.6131 4.39105ZM23.9698 23.9655C19.0259 28.9094 10.9813 28.9094 6.03744 23.9655C3.64339 21.5714 2.32432 18.3871 2.32432 15.0005C2.32432 11.6139 3.64339 8.42954 6.03744 6.03432C10.9813 1.09043 19.0259 1.0916 23.9698 6.03432C28.9125 10.9782 28.9125 19.0228 23.9698 23.9655Z" fill="#FF1B1B" />
                                        <path d="M20.0115 18.2127L16.7213 14.9273L20.0115 11.6418C20.4647 11.1886 20.4647 10.4529 20.0126 9.99849C19.5582 9.54292 18.8226 9.54409 18.3682 9.99732L15.0758 13.2851L11.7834 9.99732C11.329 9.54409 10.5933 9.54292 10.1389 9.99849C9.68568 10.4529 9.68568 11.1885 10.1401 11.6418L13.4302 14.9273L10.1401 18.2127C9.68568 18.6659 9.68568 19.4016 10.1389 19.856C10.3656 20.0838 10.6642 20.1965 10.9617 20.1965C11.2593 20.1965 11.5568 20.0826 11.7834 19.8571L15.0758 16.5694L18.3682 19.8571C18.5949 20.0838 18.8924 20.1965 19.1899 20.1965C19.4875 20.1965 19.7861 20.0826 20.0128 19.856C20.4659 19.4016 20.4659 18.6659 20.0115 18.2127Z" fill="#FF1B1B" />
                                    </svg>
                                </div>
                                <div className="notify-body">
                                    <div className="notify-title">{e.title}</div>
                                    <p>{e.text}</p>
                                </div>
                            </div>
                        case 'warning':
                            return <div style={{ marginBottom: 5 }} className="notify warning" key={i}>
                                <div className="notify-icon">
                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 0C6.70881 0 0 6.70939 0 15C0 23.2912 6.70939 30 15 30C23.2912 30 30 23.2906 30 15C30 6.70881 23.2906 0 15 0ZM15 27.6562C8.00432 27.6562 2.34375 21.9952 2.34375 15C2.34375 8.00432 8.00478 2.34375 15 2.34375C21.9957 2.34375 27.6562 8.00478 27.6562 15C27.6562 21.9957 21.9952 27.6562 15 27.6562Z" fill="#FF9800" />
                                        <path d="M15 7.55142C14.3528 7.55142 13.8281 8.07607 13.8281 8.7233V16.2698C13.8281 16.917 14.3528 17.4416 15 17.4416C15.6472 17.4416 16.1719 16.917 16.1719 16.2698V8.7233C16.1719 8.07607 15.6472 7.55142 15 7.55142Z" fill="#FF9800" />
                                        <path d="M15 22.0406C15.8737 22.0406 16.582 21.3323 16.582 20.4586C16.582 19.5848 15.8737 18.8765 15 18.8765C14.1263 18.8765 13.418 19.5848 13.418 20.4586C13.418 21.3323 14.1263 22.0406 15 22.0406Z" fill="#FF9800" />
                                    </svg>
                                </div>
                                <div className="notify-body">
                                    <div className="notify-title">{e.title}</div>
                                    <p>{e.text}</p>
                                </div>
                            </div>
                        case 'info':
                            return <div style={{ marginBottom: 5 }} className="notify info" key={i}>
                                <div className="notify-icon">
                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2912 30 30 23.2906 30 15C30 6.7088 23.2906 0 15 0C6.70882 0 0 6.7094 0 15C0 23.2912 6.7094 30 15 30ZM15 2.34375C21.9957 2.34375 27.6562 8.00479 27.6562 15C27.6562 21.9957 21.9952 27.6562 15 27.6562C8.00432 27.6562 2.34375 21.9952 2.34375 15C2.34375 8.00432 8.00479 2.34375 15 2.34375ZM15 22.4486C15.6472 22.4486 16.1719 21.9239 16.1719 21.2767V13.7302C16.1719 13.083 15.6472 12.5584 15 12.5584C14.3528 12.5584 13.8281 13.083 13.8281 13.7302V21.2767C13.8281 21.9239 14.3528 22.4486 15 22.4486ZM13.418 9.54143C13.418 8.66771 14.1263 7.9594 15 7.9594C15.8737 7.9594 16.582 8.66771 16.582 9.54143C16.582 10.4152 15.8737 11.1235 15 11.1235C14.1263 11.1235 13.418 10.4152 13.418 9.54143Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="notify-body">
                                    <div className="notify-title">{e.title}</div>
                                    <p>{e.text}</p>
                                </div>
                            </div>
                        case 'success':
                            return <div style={{ marginBottom: 5 }} className="notify success" key={i}>
                                <div className="notify-icon">
                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 0C6.7285 0 0 6.7285 0 15C0 23.2715 6.7285 30 15 30C23.2715 30 30 23.2703 30 15C30 6.72967 23.2715 0 15 0ZM15 27.6762C8.01123 27.6762 2.32377 21.9899 2.32377 15C2.32377 8.01006 8.01123 2.32377 15 2.32377C21.9899 2.32377 27.6762 8.01006 27.6762 15C27.6762 21.9899 21.9888 27.6762 15 27.6762Z" fill="#43C548" />
                                        <path d="M21.8819 9.78308C21.409 9.35317 20.6735 9.38687 20.2413 9.86206L13.1538 17.6665L9.72967 14.1855C9.27768 13.7277 8.54338 13.7208 8.08676 14.1716C7.62897 14.6212 7.622 15.3567 8.07282 15.8145L12.359 20.1716C12.5786 20.3946 12.876 20.519 13.1874 20.519C13.1944 20.519 13.2025 20.519 13.2095 20.5201C13.5302 20.5132 13.8323 20.3761 14.0472 20.1391L21.9608 11.4249C22.392 10.9484 22.3571 10.2141 21.8819 9.78308Z" fill="#43C548" />
                                    </svg>
                                </div>
                                <div className="notify-body">
                                    <div className="notify-title">{e.title}</div>
                                    <p>{e.text}</p>
                                </div>
                            </div>
                        default:
                            return <div style={{ marginBottom: 5 }} className="notify info" key={i}>
                                <div className="notify-icon">
                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2912 30 30 23.2906 30 15C30 6.7088 23.2906 0 15 0C6.70882 0 0 6.7094 0 15C0 23.2912 6.7094 30 15 30ZM15 2.34375C21.9957 2.34375 27.6562 8.00479 27.6562 15C27.6562 21.9957 21.9952 27.6562 15 27.6562C8.00432 27.6562 2.34375 21.9952 2.34375 15C2.34375 8.00432 8.00479 2.34375 15 2.34375ZM15 22.4486C15.6472 22.4486 16.1719 21.9239 16.1719 21.2767V13.7302C16.1719 13.083 15.6472 12.5584 15 12.5584C14.3528 12.5584 13.8281 13.083 13.8281 13.7302V21.2767C13.8281 21.9239 14.3528 22.4486 15 22.4486ZM13.418 9.54143C13.418 8.66771 14.1263 7.9594 15 7.9594C15.8737 7.9594 16.582 8.66771 16.582 9.54143C16.582 10.4152 15.8737 11.1235 15 11.1235C14.1263 11.1235 13.418 10.4152 13.418 9.54143Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="notify-body">
                                    <div className="notify-title">{e.title}</div>
                                    <p>{e.text}</p>
                                </div>
                            </div>
                    }
                })
            }
        </div>
    )
}

export default connect(Notification);


