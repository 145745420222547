import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios';
import { getUserId, setToken } from '../../lib/Router'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { FlatCard } from '../../components/card';
import { connect } from '../../store';
import { SecondaryBtn } from '../../components/buttons';
import { Row, Col } from "../../components/grid";
import { InputText, Select, InputAvatar, ProgressBarSteps, Ranking, InputCalendar, InputPasswordModal, InputPhone } from "../../components/input";
import HorarioTable from './horario';
import moment from "moment";

import MyService from './services'
import MyBank from './bank'
import MyFilesAndService from './filesAndService'
import Distance from './distance'
import Places from '../../components/places'

import './style.css';

const genders = [
    { value: null, label: "No informado" },
    { value: "Femenino", label: "Femenino" },
    { value: "Masculino", label: "Masculino" }
]

const Perfil = (props) => {
    let user = getUserId();
    let d = moment(user.user.nac).format('DD-MM-YYYY').split('-').map(e => parseInt(e));
    const [showMap, setShowMap] = useState(false)
    const [date, setDate] = useState({ day: d[0], month: d[1], year: d[2] })
    const [avatar, setAvatar] = useState(user.avatar);
    const [fileAvatar, setFileAvatar] = useState(null);
    const [name, setName] = useState(user.user.name);
    const [lastname, setLastname] = useState(user.user.lastname);
    const [rut, setRut] = useState(user.user.rut);
    const [phone, setPhone] = useState(user.user.phone);
    const [mail, setMail] = useState(user.user.email);
    const [address, setAddress] = useState(user.user.address);
    const [addressNum, setAddressNum] = useState(user.user.address_num);
    const [addressDepto, setAddressDepto] = useState(user.user.address_other);
    const [pass, setPass] = useState(user.user.password);
    const [genero, setGenero] = useState(user.user.gender == null || user.user.gender === "null" || user.user.gender === "[object Object]" ? { value: 'null', label: 'No informado' } : user.user.gender);
    const [region, setRegion] = useState(user.user.region);
    const [comuna, setComuna] = useState(user.user.comune);
    const [distance, setDistance] = useState(user.user.distance)
    const [listServ, setListServ] = useState(null)
    const [coords, setCoords] = useState({ lat: user.user.lat, lng: user.user.lng });

    const [errName, setErrName] = useState(false);
    const [errLastname, setErrLastname] = useState(false);
    const [errMail, setErrMail] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errAddressDepto, setErrAddressDepto] = useState(false);
    const [errPassEquals, setErrPassEquals] = useState(false);
    const [errCurrentPass, setErrCurrentPass] = useState(false)
    const [errAddress, setErrAddress] = useState(false)


    const update = () => {
        if (name === "" || name.length == 0) return setErrName(true);
        else setErrName(false);

        if (lastname === "" || lastname.length == 0) return setErrLastname(true);
        else setErrLastname(false);

        const rt = /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/;
        if (!rt.test(phone)) return setErrPhone(true);
        else setErrPhone(false);

        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(mail)) return setErrMail(true);
        else setErrMail(false);

        if (address === "" || address.length == 0) return setErrAddress(true);
        else setErrAddress(false);

        let form = new FormData();
        form.append("name", name);
        form.append("lastname", lastname);
        form.append("email", mail);
        form.append("phone", phone);
        form.append("lat", coords.lat);
        form.append("lon", coords.lng);
        form.append("address", address);
        form.append("address_other", addressDepto);
        form.append("gender", genero);
        let d = new Date();
        d.setDate(date.day)
        d.setMonth(date.month - 1)
        d.setFullYear(date.year)
        form.append("nac", d);

        if (fileAvatar != null) form.append("avatar", fileAvatar, fileAvatar.name);

        axios.put(`/api/user/${user.userId}`, form)
            .then(res => {
                if (res.data.success) {
                    setToken(res.data.token);
                    props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
                }
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }


    const setPassword = (actual, nuevo) => {
        axios.put(`/api/user/${user.userId}/setpassword`, { current: actual, password: nuevo })
            .then(res => {
                if (res.data.success) {
                    props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
                    setErrCurrentPass(false)
                } else {
                    props.notify({ type: 'error', text: 'Password no coincide', title: "Actualizar" })
                    setErrCurrentPass(true)
                }
            })
            .catch(err => { props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }) })
    }

    const alertServ = () => {
        props.store.my_services.map(e => {
            let list = Object.assign([], listServ)
            if (e.servicios.length == 0) list.push(<li className="colorErr">Falta agregar servicios en la categoria {e.name}</li>);
            setListServ(list)
        });
    }

    useEffect(() => {
        alertServ();
    }, [])

    return (
        <Main>
            <Row>
                <Col>
                    <Header title="Perfil de usuario" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FlatCard>
                        <Row className="no-gutters align-items-center profileContentRow">
                            <Col xs={12} md={3} className="profilePicture text-center">
                                <div className="p-4 text-center">
                                    <InputAvatar src={avatar} onChange={file => setFileAvatar(file)} />
                                    <div className="px-3">
                                        <ProgressBarSteps str={user.user.steps.str} value={((user.user.steps.step * 100) / 5)} />
                                        {user.user.steps.missing.length > 0 ? <div>
                                            <div style={{ fontSize: 10, fontWeight: 600 }} className="text-center mb-4">Aun debes completar los siguientes pasos</div>
                                            <ul style={{ margin: 0, padding: 0 }} className="text-left">
                                                {user.user.steps && user.user.steps.missing.map(e => {
                                                    return <li>{e}</li>
                                                })}
                                            </ul>
                                        </div> : null}
                                        <ul className="ulErr">{listServ}</ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={9} className="pl-4 border-left">
                                <form>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <InputText err={errName} placeholder="Nombre" label="Nombre" value={name} onChange={value => setName(value)} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputText err={errLastname} placeholder="Apellido" label="Apellido" value={lastname} onChange={value => setLastname(value)} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputText disabled={true} label="Rut" value={rut} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <InputPhone err={errPhone} label="Teléfono" value={phone} onChange={value => setPhone(value)} />
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <InputText err={errMail} placeholder="email@dominio.com" label="Email" value={mail} onChange={value => setMail(value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={10}>
                                            <Places value={address} onChangeCoords={setCoords} onChangeAddress={setAddress} />
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <InputText err={errAddressDepto} placeholder="Depto/Block" label="Depto/Block" value={addressDepto} onChange={value => setAddressDepto(value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <InputCalendar maxDateToDay={true} label="Fecha de nacimiento" value={date} onChange={date => setDate(date)} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Select disabled={false} label="Genero" options={genders} placeholder="Seleccionar genero" value={genero} onChange={v => {
                                                setGenero(v.value);
                                            }} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <InputPasswordModal invalidCurrentPass={errCurrentPass} label="Contraseña" value={pass} onChange={setPassword} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} className="text-right px-4">
                                            <SecondaryBtn onClick={() => update()}>Guardar</SecondaryBtn>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            {errPassEquals ? <span className="error-label">Las contraseñas no coinciden</span> : null}
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </FlatCard>
                </Col>
            </Row>

            <Row className="userWorkSettings">
                <Col>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a onClick={() => setShowMap(false)} className="nav-link active" id="user-files-tab" data-toggle="tab" href="#user-files" role="tab" aria-controls="user-files" aria-selected="true"><div className="step-number mx-auto">1</div>Archivos y Servicios</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => setShowMap(false)} className="nav-link" id="user-availability-tab" data-toggle="tab" href="#user-availability" role="tab" aria-controls="user-availability" aria-selected="false"><div className="step-number mx-auto">2</div>Disponibilidad horaria</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => setShowMap(false)} className="nav-link" id="user-rate-tab" data-toggle="tab" href="#user-rate" role="tab" aria-controls="user-rate-tab" aria-selected="false"><div className="step-number mx-auto">3</div>Tarifas</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => setShowMap(true)} className="nav-link" id="distance-tab" data-toggle="tab" href="#distance" role="tab" aria-controls="distance-tab" aria-selected="false"><div className="step-number mx-auto">4</div>Distancia</a>
                        </li>
                        <li className="nav-item">
                            <a onClick={() => setShowMap(false)} className="nav-link" id="user-bank-tab" data-toggle="tab" href="#user-bank" role="tab" aria-controls="user-bank-tab" aria-selected="false"><div className="step-number mx-auto">5</div>Datos Bancarios</a>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="user-files" role="tabpanel" aria-labelledby="user-files-tab">

                            <MyFilesAndService selected={props.store.my_services} service={props.store.cat_services} />

                        </div>
                        <div className="tab-pane fade" id="user-availability" role="tabpanel" aria-labelledby="user-availability-tab">

                            <HorarioTable user={user.user} />

                        </div>
                        <div className="tab-pane fade" id="user-rate" role="tabpanel" aria-labelledby="user-rate-tab">

                            <MyService selected={props.store.my_services} />

                        </div>
                        <div className="tab-pane fade" id="user-bank" role="tabpanel" aria-labelledby="user-bank-tab">

                            <MyBank />

                        </div>
                        <div className="tab-pane fade" id="distance" role="tabpanel" aria-labelledby="distance-tab">
                            {showMap ? <Distance userId={user.userId} distance={distance} address={`${address} ${addressNum} ${comuna} ${region}, Chile`} /> : null}
                        </div>
                    </div>
                    <br /><br /><br /><br /><br /><br /><br />
                </Col>
            </Row>
        </Main>
    )
}

export default connect(Perfil);