import endpoint from './Endpoint'
import axios from 'axios';

export const validaRut = (rutCompleto) => {
    if (rutCompleto.length < 7) return false;
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (dv(rut) == digv);
}


const dv = (T) => {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
}


export const getUrl = (path) => {
    if (path != undefined) return path.includes('http') ? path : endpoint + path;
    return null;
}


export const loadData = (api, reducer, select, cb) => {
    axios.get(api)
        .then(response => {
            if (reducer != undefined) {
                if (select != undefined) {
                    reducer(response.data.data.map(e => {
                        e['value'] = e[select['value']]
                        e['label'] = e[select['label']]
                        return e;
                    }))
                } else reducer(response.data.data);
            }

            if (cb != undefined) cb(response);
        })
        .catch(err => console.log(err))
}

export const parseDataSelect = (data, value, label) => {
    return data.map(e => {
        e['value'] = e[value];
        e['label'] = e[label];
        return e;
    })
}