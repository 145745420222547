import React, { useState, useEffect } from 'react'
import { Row, Col } from '../../components/grid'
import { Card } from '../../components/card'
import { connect } from '../../store'
import { getUserId } from '../../lib/Router'
import { ModalSimple } from '../../components/modal'
import { RankingInput } from "../../components/input";
import moment from 'moment'
import DatePicker, { utils, Calendar } from 'react-modern-calendar-datepicker';
import { SecondaryBtn, BtnSee } from '../../components/buttons'
import { localeEs } from '../perfil/locale'
import io from '../../sockets'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './style.css'
import axios from 'axios'
import Table from '../../components/table'
import Calidad from '../../assets/icons/attitudes/calidad.svg';
import Puntualidad from '../../assets/icons/attitudes/puntual.svg';
import Amabilidad from '../../assets/icons/attitudes/amabilidad.svg';
import Limpieza from '../../assets/icons/attitudes/limpieza.svg';
import Seguridad from '../../assets/icons/attitudes/seguridad.svg';
import Flexibilidad from '../../assets/icons/attitudes/flexible.svg';

const Simulation = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [current, setCurrent] = useState(null)
    const [services, setServices] = useState([])
    const [activeOne, setActiveOne] = useState(null)
    const [activeTwo, setActiveTwo] = useState(null)
    const [activeThree, setActiveThree] = useState(null)
    const [dateStart, setDateStart] = useState(null)
    const [selectDay, setSelectDay] = useState(null)
    const [servActive, setServActive] = useState(null)
    const [hourActive, setHourActive] = useState(null)
    const [selectShedule, setSelectShedule] = useState([])
    const [events, setEvents] = useState([])
    const [reservs, setReservs] = useState([])
    const [pay, setPay] = useState([])
    const [calidad, setCalidad] = useState(false)
    const [puntualidad, setPuntualidad] = useState(false)
    const [amabilidad, setAmabilidad] = useState(false)
    const [limpieza, setLimpieza] = useState(false)
    const [seguridad, setSeguridad] = useState(false)
    const [flexibilidad, setFlexibilidad] = useState(false)
    const [calificacion, setCalificacion] = useState(0)
    const user = getUserId()

    const minutesOfDay = function (m) {
        return m.minutes() + m.hours() * 60;
    }

    const intervalHour = (init, end, inter) => {
        return new Promise((resolve, reject) => {
            const initHour = moment({ hour: init.hour, minute: init.minute })
            const endHour = moment({ hour: end.hour, minute: end.minute })
            const interval = inter;
            const hours = [];
            var actualHour = initHour;

            while (minutesOfDay(actualHour) <= minutesOfDay(endHour)) {
                if (minutesOfDay(actualHour) <= minutesOfDay(endHour)) {
                    hours.push(actualHour.format('HH:mm'));
                }

                actualHour = actualHour.add(interval, 'minute')

                if (actualHour.isSameOrAfter(endHour) || actualHour.format('HH:mm') === endHour.format('HH:mm')) {
                    resolve(hours);
                }
            }
        })
    }

    const intervalOfTheWork = (data) => {
        let result = []
        return new Promise((resolve, reject) => {
            if (data.enabled) {
                for (let key in data) {
                    if (key === 'hour') {
                        let init = data[key]['from'].split(':');
                        let to = data[key]['to'].split(':');
                        intervalHour({ hour: parseInt(init[0]), minute: parseInt(init[1]) }, { hour: parseInt(to[0]), minute: parseInt(to[1]) }, 45)
                            .then(res => {
                                if (res.length > 1) {
                                    resolve(res.slice(0, res.length - 1))
                                } else {
                                    resolve(res)
                                }
                            });
                    }
                }
            } else {
                resolve(result)
            }

        })
    }

    const parseHoursToArray = (data, cb) => {
        let i = 0;
        return new Promise((resolve, reject) => {

            if (data.length == 0) return resolve();
            while (i < data.length) {
                if (cb != undefined) cb(data[i]);
                if (i == data.length - 1) resolve();
                i++;
            }
        })
    }

    const availableDay = (day) => {
        const data = []
        const pushElement = (element) => data.push(element);
        return new Promise(async (resolve, reject) => {
            if (day.enabled) {
                parseHoursToArray(await intervalOfTheWork(day['ma']), pushElement)
                    .then(async () => {
                        parseHoursToArray(await intervalOfTheWork(day['mm']), pushElement)
                            .then(async () => {
                                parseHoursToArray(await intervalOfTheWork(day['td']), pushElement)
                                    .then(async () => {
                                        parseHoursToArray(await intervalOfTheWork(day['nc']), pushElement)
                                            .then(async () => resolve(data))
                                    })
                            })
                    })
            } else {
                resolve(data)
            }
        })

    }

    const shedule = (day) => {
        let available = user.user.availability
        switch (day) {
            case 0:
                availableDay(available['lun'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 1:
                availableDay(available['mar'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 2:
                availableDay(available['mie'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 3:
                availableDay(available['jue'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 4:
                availableDay(available['vie'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 5:
                availableDay(available['sab'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            case 6:
                availableDay(available['dom'])
                    .then(res => {
                        setSelectShedule(res)
                    })
                break;
            default:
                return setSelectShedule([])
        }
    }

    const getSales = () => {
        axios.get(`/api/sales/reserv/${user.userId}`)
            .then(res => {
                setReservs(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getSalesPay = () => {
        axios.get(`/api/sales/pay/${user.userId}`)
            .then(res => {
                setPay(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const reservation = () => {
        axios.post('/api/calendar/events', {
            serv: servActive._id,
            date: moment({ year: dateStart.year, month: dateStart.month - 1, day: dateStart.day }).format('YYYY-MM-DD'),
            hour: hourActive,
            client: "60c24ba0c7b33927dcde6812",
            expert: user.userId,
            message: "Mensaje desde el simulador"
        })
            .then(res => {
                props.notify({ type: "success", title: "Exito", text: "Solicitud de reserva realizada" })
            })
            .catch(err => {
                props.notify({ type: "error", title: "Error", text: "Solicitud de reserva no realizada" })
            })
    }

    const getEventsCalendar = () => {
        axios.get(`/api/simulador/calendar/events/all/${user.userId}`)
            .then(res => {
                console.log('events',res.data.data)
                setEvents(res.data.data);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getEventsCalendar();
        getSales();
        getSalesPay()
        io.on('new_shedule_pending', getEventsCalendar);
        io.on('new_shedule_event', getEventsCalendar);
        io.on('notify_sales', getSales);
        io.on('notify_sales', getSalesPay)
    }, [])

    return (
        <div className="px-5">
            <Row>
                <Col md={6}>
                    <Card title="Seleccion de categoria de servicios">
                        <ul className="ulServ p-4">
                            {props.store.my_services.map((e, i) => {
                                return (
                                    <li className={`${activeOne == i ? 'active' : ''}`} onClick={() => {
                                        setServices(e.servicios)
                                        setActiveOne(i);
                                    }}>{e.label}</li>
                                )
                            })}
                        </ul>
                        <br />
                    </Card>
                </Col>
                <Col md={6}>
                    <Card title="Seleccion de servicios">
                        <ul className="ulServ p-4">
                            {services.map((e, i) => {
                                return (
                                    <li className={`${activeTwo == i ? 'active' : ''}`} onClick={() => {
                                        setActiveTwo(i);
                                        setServActive(e);
                                    }}>{e.name}</li>
                                )
                            })}
                        </ul>
                        <br />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <Card title="Seleccion agenda">
                        <Calendar
                            value={dateStart}
                            onChange={(v) => {
                                setDateStart(v);
                                let d = new Date(`${v.year}-${v.month}-${v.day}`)
                                if (selectDay != d.getDay()) setSelectDay(d.getDay());
                                console.log(d)
                                shedule(d.getDay())
                            }}
                            inputPlaceholder="Selecciona tu vacaciones"
                            // shouldHighlightWeekends
                            calendarClassName="responsive-calendar"
                            minimumDate={utils().getToday()}
                            locale={localeEs}
                        />
                    </Card>
                </Col>
                <Col md={9}>
                    <Card title="Seleccion agenda">
                        <ul className="ulServ ul-inline p-4" style={{ height: 200 }}>
                            {selectShedule.length == 0 ? <div>No hay horas disponibles</div> : selectShedule.map((e, i) => {
                                return (
                                    <li className={`${activeThree == i ? 'active' : ''}`} onClick={() => {
                                        setActiveThree(i)
                                        setHourActive(e)
                                    }}>{e}</li>
                                )
                            })}
                        </ul>
                        <div className="text-right p-4">
                            <SecondaryBtn onClick={reservation}>Solicitar reserva</SecondaryBtn>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card title="Pago reserva">
                        <Table keyID="_id" columns={[
                            { key: 'clientName', text: 'Cliente' },
                            { key: 'service.desc', text: 'Servicio' },
                            { key: 'dateStr', text: 'Fecha' },
                            { key: 'hour', text: 'Hora' },
                            { key: 'accept', text: 'Estado' },
                            {
                                key: 'controls', 'minWidth': 150, text: 'Acciones', data: null, elem: (user) => {
                                    return <div>
                                        <SecondaryBtn onClick={() => {
                                            axios.post('/api/pagoreserva', {
                                                sheduleEvent: user._id,
                                                client: user.clientID,
                                                expert: user.expertID,
                                                service: user.serv,
                                                transactionReserv: Math.floor(Math.random() * (1000 - 100)) + 100,
                                                dateReserv: moment().format('DD-MM-YYYY HH:mm:ss'),
                                                reserv: ((parseInt(user.service.min) * 20) / 100),
                                                total: parseInt(user.service.min),
                                            })
                                                .then(res => {
                                                    getEventsCalendar();
                                                    props.notify({ type: "success", title: "Exito", text: "Transacción realizada" })
                                                })
                                                .catch(err => {
                                                    props.notify({ type: "error", title: "Error", text: "Transacción no realizada" })
                                                })
                                        }}>Pagar reserva</SecondaryBtn>
                                    </div>
                                }
                            }
                        ]} data={events} background="#FF8B03" paginationClass="table-buttons" exports={false} autoHeight={false} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card title="Servicios reservados">
                        <Table keyID="_id" columns={[
                            { key: 'order', text: 'Numero de orden' },
                            { key: 'service.name', text: 'Servicio' },
                            { key: 'client.name', text: 'Nombre cliente' },
                            { key: 'client.lastname', text: 'Apellido cliente' },
                            { key: 'transactionReserv', text: 'Numero de transacción' },
                            {
                                key: 'controls', 'minWidth': 150, text: 'Acciones', data: null, elem: (user) => {
                                    return <div>
                                        <SecondaryBtn onClick={() => {
                                            setOpenModal(true);
                                            setCurrent(user);
                                        }}>Pagar servicio</SecondaryBtn>
                                    </div>
                                }
                            }
                        ]} data={reservs} background="#FF8B03" paginationClass="table-buttons" exports={false} autoHeight={false} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card title="Servicio realizado">
                        <Table keyID="_id" columns={[
                            { key: 'order', text: 'Numero de orden' },
                            { key: 'service.name', text: 'Servicio' },
                            { key: 'client.name', text: 'Nombre cliente' },
                            { key: 'client.lastname', text: 'Apellido cliente' },
                            { key: 'transactionRemaining', text: 'Numero de transacción' },
                        ]} data={pay} background="#FF8B03" paginationClass="table-buttons" exports={false} autoHeight={false} />
                    </Card>
                </Col>
            </Row>

            <ModalSimple open={openModal} cancel={true} accept={true} onClose={setOpenModal} onClick={() => {
                axios.post('/api/ratings', {
                    expert: current.expert,
                    service: current.service._id,
                    qualification: calificacion,
                    calidad: calidad,
                    puntualidad: puntualidad,
                    amabilidad: amabilidad,
                    limpieza: limpieza,
                    seguridad: seguridad,
                    flexibilidad: flexibilidad,
                })
                    .then(res => {
                        axios.post(`/api/pagototal/${current._id}`, {
                            dateRemaining: moment().format('DD-MM-YYYY HH:mm:ss'),
                            remaining: (parseInt(current.total) - parseInt(current.reserv)),
                            transactionRemaining: Math.floor(Math.random() * (1000 - 100)) + 100,
                            event: current.event._id
                        })
                            .then(response => {
                                props.notify({ type: "success", title: "Exito", text: "Transacción realizada" })
                            })
                            .catch(err => {
                                props.notify({ type: "error", title: "Error", text: "Transacción no realizada" })
                            })
                    })
                    .catch(err => {
                        props.notify({ type: "error", title: "Error", text: "Transacción no realizada" })
                    })
            }}>
                <div>
                    <br /><br />
                    <p>Califica al experto</p>
                    <Row className="text-center mb-4">
                        <Col md={12}>
                            <RankingInput value={0} onChange={setCalificacion} />
                        </Col>
                    </Row>
                    <p>Valora las aptitudes del experto</p>
                    <Row className="text-center mb-4">
                        <Col md={4}>
                            <CheckAttribute img={Calidad} onChange={setCalidad} />
                            <p>Calidad</p>
                        </Col>
                        <Col md={4}>
                            <CheckAttribute img={Puntualidad} onChange={setPuntualidad} />
                            <p>Puntualidad</p>
                        </Col>
                        <Col md={4}>
                            <CheckAttribute img={Amabilidad} onChange={setAmabilidad} />
                            <p>Amabilidad</p>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={4}>
                            <CheckAttribute img={Limpieza} onChange={setLimpieza} />
                            <p>Limpieza</p>
                        </Col>
                        <Col md={4}>
                            <CheckAttribute img={Seguridad} onChange={setSeguridad} />
                            <p>Seguridad</p>
                        </Col>
                        <Col md={4}>
                            <CheckAttribute img={Flexibilidad} onChange={setFlexibilidad} />
                            <p>Flexibilidad</p>
                        </Col>
                    </Row>
                    <br /><br />
                    <br /><br />
                    <br /><br />
                </div>
            </ModalSimple>
        </div>
    )
}

export default connect(Simulation);

const CheckAttribute = (props) => {
    const id = Math.floor(Math.random() * (1000 - 100)) + 100;

    const change = (value) => {
        if (props.onChange != undefined) props.onChange(value.target.checked)
    }

    return (
        <div className="attributeCheck">
            <input type="checkbox" id={id} onChange={change} />
            <label htmlFor={id} style={{ position: "relative" }}>
                <div className="check" style={{ position: 'absolute', background: "white", width: 20, height: 20, borderRadius: 100, top: 0, right: 0, textAlign: "center" }}>
                    <svg width="15" height="15" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M497.36 69.9948C489.828 62.4498 477.607 62.4368 470.075 69.9628L238.582 300.845L155.06 210.132C147.843 202.298 135.641 201.79 127.794 209.006C119.953 216.223 119.451 228.431 126.668 236.272L223.794 341.753C227.351 345.619 232.329 347.864 237.578 347.973C237.719 347.979 237.855 347.979 237.99 347.979C243.091 347.979 247.998 345.953 251.613 342.351L497.322 97.2858C504.873 89.7608 504.886 77.5398 497.36 69.9948Z" fill="black" />
                        <path d="M492.703 236.703C482.045 236.703 473.407 245.341 473.407 256C473.407 375.883 375.883 473.407 256 473.407C136.124 473.407 38.593 375.883 38.593 256C38.593 136.124 136.124 38.593 256 38.593C266.658 38.593 275.297 29.955 275.297 19.297C275.297 8.638 266.658 0 256 0C114.84 0 0 114.84 0 256C0 397.154 114.84 512 256 512C397.154 512 512 397.154 512 256C512 245.342 503.362 236.703 492.703 236.703Z" fill="black" />
                    </svg>
                </div>
                <img style={{ width: 50 }} src={props.img} />
            </label>
        </div>
    )
}