import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getUserId } from '../../lib/Router'
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { CardWithTitle } from '../../components/card';
import { connect } from '../../store';
import { Row, Col } from "../../components/grid";
import { Ranking } from "../../components/input";
import { Bar } from 'react-chartjs-2';
import ProgressStatistics from '../../components/progressStatistics';
import TableRanking from "../../components/tableRanking";
import CircularProgress from "../../components/circularProgress";
import Calidad from '../../assets/icons/attitudes/calidad.svg';
import Puntualidad from '../../assets/icons/attitudes/puntual.svg';
import Amabilidad from '../../assets/icons/attitudes/amabilidad.svg';
import Limpieza from '../../assets/icons/attitudes/limpieza.svg';
import Seguridad from '../../assets/icons/attitudes/seguridad.svg';
import Flexibilidad from '../../assets/icons/attitudes/flexible.svg';
import "react-circular-progressbar/dist/styles.css";
import './style.css';


const Dashboard = (props) => {
	const { user, userId } = getUserId();
	const [qualification, setQualification] = useState(0);
	const [total, setTotal] = useState(0);
	const [calidad, setCalidad] = useState(0);
	const [puntualidad, setPuntualidad] = useState(0);
	const [amabilidad, setAmabilidad] = useState(0);
	const [limpieza, setLimpieza] = useState(0);
	const [seguridad, setSeguridad] = useState(0);
	const [flexibilidad, setFlexibilidad] = useState(0);
	const [rankings, setRankins] = useState([]);
	const [game, setGamefication] = useState(null)
	const history = useHistory();

	const semaforo = (num) => {
		if (num < 51) return "255, 63, 63"; //red
		else if (num > 79) return "73, 195, 158"; // gree
		else return "253, 173, 52" // yellos
	}

	const [data, setData] = useState({
		labels: [],
		datasets: [{
			barPercentage: 0.5,
			label: 'Ganancias',
			data: [],
			backgroundColor: [
				'rgba(255, 27, 27, 1)',
				'rgba(255, 160, 0, 1)',
				'rgba(253, 197, 52, 1)',
				'rgba(62, 178, 67, 1)',
				'rgba(73, 195, 157, 1)',
				'rgba(110, 161, 248, 1)',
				'rgba(70, 102, 222, 1)'
			],
			borderColor: [
				'rgba(255, 27, 27, 1)',
				'rgba(255, 160, 0, 1)',
				'rgba(253, 197, 52, 1)',
				'rgba(62, 178, 67, 1)',
				'rgba(73, 195, 157, 1)',
				'rgba(110, 161, 248, 1)',
				'rgba(70, 102, 222, 1)'

			],
			borderWidth: 2,
		}]
	})

	const [options, setOptions] = useState({
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: false
		},

		tooltips: {
			mode: 'index',
			intersect: false,
			callbacks: {
				label: function (t, d) {
					return 'Ganancia: ' + new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.yLabel)
				},
				title: function (t, d) {
					return 'Ocupación de la agenda: ' + t[0].label;
				}
			}
		},

		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: "GANANCIA",
				},
				ticks: {
					beginAtZero: true,
					min: 0,
					callback: function (value, index, values) {
						return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
					}
				}
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: "OCUPACIÓN DE LA AGENDA",
				},
				ticks: {
					min: 0,
				}
			}]
		}
	})

	const setDataChart = (d) => {
		setData({
			labels: d.map(e => e.label),
			datasets: [{
				barPercentage: 0.5,
				label: 'Ganancias',
				data: d.map(e => e.total),
				backgroundColor: [
					'rgba(255, 27, 27, 1)',
					'rgba(255, 160, 0, 1)',
					'rgba(253, 197, 52, 1)',
					'rgba(62, 178, 67, 1)',
					'rgba(73, 195, 157, 1)'
				],
				borderColor: [
					'rgba(255, 27, 27, 1)',
					'rgba(255, 160, 0, 1)',
					'rgba(253, 197, 52, 1)',
					'rgba(62, 178, 67, 1)',
					'rgba(73, 195, 157, 1)'
				],
				borderWidth: 2,
			}]
		})
	}

	const getChart = () => {
		axios.get(`/api/sales/projection/${getUserId().userId}`)
			.then(res => {
				console.log(res);
				setDataChart(res.data.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	useEffect(() => {
		if (user.steps.step < 5) history.push('/perfil')
		axios.get(`/api/ratings/${userId}`)
			.then(res => {
				if (res.data.data) {
					let data = res.data.data;
					setQualification(data.ponderacion);
					setTotal(data.qualification);
					setCalidad(parseInt(data.calidad));
					setPuntualidad(parseInt(data.puntualidad));
					setAmabilidad(parseInt(data.amabilidad));
					setLimpieza(parseInt(data.limpieza));
					setSeguridad(parseInt(data.seguridad));
					setFlexibilidad(parseInt(data.flexibilidad));
				}
			})
			.catch(err => console.log('Err:', err))

		axios.get(`/api/ranking/${userId}`)
			.then(res => {
				if (res.data.data) {
					setRankins(res.data.data)
				}
			})
			.catch(err => console.log(err))


		axios.get(`/api/gamemifaction/${userId}`)
			.then(res => {
				if (res.data.success) setGamefication(res.data.data)
			})
			.catch(err => console.log(err))


		getChart();

	}, [])

	return (
		<Main>
			<Row>
				<Col>
					<Header title="Bienvenido a Mi Panel" />
				</Col>
			</Row>
			<Row>
				<Col xs={12} md={8}>
					<CardWithTitle style={{ minHeight: 500, display: "flex", alignItems: "center" }} title="Mi reporte comercial" subtitle="Proyección de ganancias">
						<div className="pt-4">
							<Bar
								data={data}
								width={200}
								height={300}
								options={options}
							/>
						</div>
					</CardWithTitle>
				</Col>
				<Col xs={12} md={4}>
					<CardWithTitle style={{ minHeight: 500, display: "flex", alignItems: "center" }} title="Mis Estadísticas">
						<div className="text-center">
							<div className="px-4 d-flex justify-content-center">
								<ProgressStatistics value={game && game.progress} pos={game && game.gamefication} />
							</div>
							<p className="m-0 pt-4">Mis calificaciones</p>
							<div className="m-0">
								<Row>
									<Col md={12}>
										<Ranking value={qualification} />
										<br />
									</Col>
									<Col md={6}>
										<p style={{ margin: 0 }}>Nota</p>
										<p style={{ margin: 0, fontSize: 24 }}><strong>{game && game.calification}</strong></p>
									</Col>
									<Col md={6}>
										<p style={{ margin: 0 }}>% Rechazo</p>
										<p style={{ margin: 0, fontSize: 24 }}><strong>{game && game.reject}%</strong></p>
									</Col>
								</Row>
							</div>
						</div>
					</CardWithTitle>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<CardWithTitle title="Mis Aptitudes">
						<Row>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={puntualidad} rgb={semaforo(puntualidad)} img={Puntualidad} attitudes="Puntualidad" />
								</div>
							</Col>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={amabilidad} rgb={semaforo(amabilidad)} img={Amabilidad} attitudes="Amabilidad" />
								</div>
							</Col>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={flexibilidad} rgb={semaforo(flexibilidad)} img={Flexibilidad} attitudes="Flexibilidad" />
								</div>
							</Col>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={limpieza} rgb={semaforo(limpieza)} img={Limpieza} attitudes="Orden y Limpieza" />
								</div>
							</Col>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={calidad} rgb={semaforo(calidad)} img={Calidad} attitudes="Calidad" />
								</div>
							</Col>
							<Col xs={12} md={2} className="d-flex justify-content-center p-4">
								<div style={{ width: "65%" }}>
									<CircularProgress value={seguridad} rgb={semaforo(seguridad)} img={Seguridad} attitudes="Seguridad" />
								</div>
							</Col>
						</Row>
					</CardWithTitle>
				</Col>
				<Col xs={12}>
					<CardWithTitle title="Ranking por servicio">
						<div className="pt-3"></div>
						<div className="pr-1">
							<TableRanking data={rankings} />
						</div>
					</CardWithTitle>
				</Col>
			</Row>
		</Main>
	)
}

export default connect(Dashboard);

