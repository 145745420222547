import React, { useState, useEffect, useRef } from "react";
// Componentes
import axios from 'axios';
import { getUserId, setToken } from '../../lib/Router'
import { BtnModalAdd, SecondaryBtn, WhiteBtn } from '../../components/buttons'
import { FlatCard } from '../../components/card';
import { connect } from '../../store';
import { BtnDelete } from '../../components/buttons';
import { Row, Col } from "../../components/grid";
import { InputText, InputNumber, InputTextArea, Select } from "../../components/input";
import Table from '../../components/table';
import Modal, { ModalCalendar, ModalSimple } from "../../components/modal";
import './style.css';
import { loadData } from '../../utils'

const timeInterval = [
    { value: 45, label: '45 Min.' },
    { value: 60, label: '1 Hr.' },
    { value: 120, label: '2 Hrs' },
    { value: 180, label: '3 Hrs' },
    { value: 240, label: '4 Hrs' },
    { value: 360, label: 'Medio día (6 Hrs)' },
    { value: 720, label: 'Día completo (12 Hrs)' },
]

const TabService = (props) => {
    const [openModal, setOpenModal] = useState(false)

    const [newService, setNewService] = useState('');
    const [newServiceName, setNewServiceName] = useState('');
    const [newServiceDesc, setNewServiceDesc] = useState('');
    const [newServiceMin, setNewServiceMin] = useState('');
    const [newServiceMax, setNewServiceMax] = useState('');
    const [newServiceTime, setNewServiceTime] = useState('');
    const [selectService, setSelectService] = useState({});

    const [catService, setCatService] = useState('');
    const [nameService, setNameService] = useState('');
    const [descService, setDescService] = useState('');
    const [minService, setMinService] = useState('');
    const [maxService, setMaxService] = useState('');
    const [timeService, setTimeService] = useState('');

    const [errService, setErrService] = useState(false);
    const [errServiceName, setErrServiceName] = useState(false);
    const [errServiceMin, setErrServiceMin] = useState(false);
    const [errServiceMax, setErrServiceMax] = useState(false);
    const [errServiceTime, setErrServiceTime] = useState(false);
    const [errServiceMinMayorToMax, setErrServiceMinMayorToMax] = useState(false);

    const [eService, setEService] = useState(false);
    const [eServiceName, setEServiceName] = useState(false);
    const [eServiceMin, setEServiceMin] = useState(false);
    const [eServiceMax, setEServiceMax] = useState(false);
    const [eServiceTime, setEServiceTime] = useState(false);
    const [eServiceMinMayorToMax, setEServiceMinMayorToMax] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectTab, setSelectTab] = useState(0);
    const [services, setServices] = useState([])
    const [currentId, setCurrentId] = useState('');
    const [tarifaAlta, setTarifaAlta] = useState(false)
    const [tarifaBaja, setTarifaBaja] = useState(false)

    useEffect(() => {
        setSelectService(services.find(e => e._id === newServiceName.value))
    }, [newServiceName])


    const createService = () => {
        if (newService === "" || newService.length == 0) return setErrService(true);
        else setErrService(false);

        if (newServiceName === "" || newServiceName.length == 0) return setErrServiceName(true);
        else setErrServiceName(false);

        if (newServiceMin == 0 || newServiceMin == null) return setErrServiceMin(true);
        else setErrServiceMin(false);

        if (newServiceMax == 0 || newServiceMax == null) return setErrServiceMax(true);
        else setErrServiceMax(false);

        if (parseInt(newServiceMax) < parseInt(newServiceMin) || parseInt(newServiceMin) == parseInt(newServiceMax)) return setErrServiceMinMayorToMax(true);
        else setErrServiceMinMayorToMax(false);

        if (newServiceTime === "" || newServiceTime.length == 0) return setErrServiceTime(true);
        else setErrServiceTime(false);

        axios.post(`/api/user/services`, {
            service: newServiceName.value,
            desc: newServiceDesc,
            min: newServiceMin,
            max: newServiceMax,
            time: newServiceTime.value,
            idCat: newService.value,
            idUser: getUserId().userId,
        })
            .then(res => {
                if (res.data.success) {
                    props.notify({ type: 'success', text: 'Servicio creado', title: "Nuevo servicio" })
                    clearNewService();
                    setOpenModal(false);
                    loadData(`/api/user/myservices/${getUserId().userId}`, props.setMyService, { value: '_id', label: 'name' })
                } else {
                    props.notify({ type: 'error', text: 'No puede duplicar servicio', title: "Servicio existente" })
                    clearNewService();
                    setOpenModal(false);
                }
            })
            .catch(err => {
                clearNewService();
                setOpenModal(false);
                props.notify({ type: 'error', text: 'Error al crear el servicio', title: "Nuevo Servicio" })
            })
    }

    const clearNewService = () => {
        setTimeout(() => {
            setNewService(null);
            setNewServiceName('');
            setNewServiceDesc(null);
            setNewServiceMax('');
            setNewServiceMin('');
            setNewServiceTime(null);
            setSelectService(null);
            setTarifaBaja(false);
            setTarifaAlta(false);
        }, 1000)
    }

    const clearRecommended = () => {
        setCatService(null);
        setNameService('');
        setDescService('');
        setMaxService('');
        setMinService('');
        setTimeService(null);
        setTarifaBaja(false);
        setTarifaAlta(false);
    }

    const createRecommendService = () => {
        if (catService === "" || catService.length == 0) return setEService(true);
        else setEService(false);

        if (nameService === "" || nameService.length == 0) return setEServiceName(true);
        else setEServiceName(false);

        if (minService == 0 || minService == null) return setEServiceMin(true);
        else setEServiceMin(false);

        if (maxService == 0 || maxService == null) return setEServiceMax(true);
        else setErrServiceMax(false);

        if (parseInt(maxService) < parseInt(minService) || parseInt(minService) == parseInt(maxService)) return setEServiceMinMayorToMax(true);
        else setEServiceMinMayorToMax(false);

        if (timeService === "" || timeService.length == 0) return setEServiceTime(true);
        else setEServiceTime(false);

        axios.post(`/api/recommended/service`, {
            desc: descService,
            min: minService,
            max: maxService,
            time: timeService.value,
            idCat: catService.value,
            idUser: getUserId().userId,
            name: nameService
        })
            .then(res => {
                if (res.data.success) {
                    clearRecommended();
                    setOpenModal(false);
                    props.notify({ type: 'success', text: 'Sugerencia recibida. Gracias', title: "Nueva sugerencia" })
                }
            })
            .catch(err => {
                console.log(err);
                clearRecommended();
                setOpenModal(false);
                props.notify({ type: 'error', text: 'Error al sugerir el servicio', title: "Sugerir Nuevo Servicio" })
            })
    }

    const onClick = () => {
        if (selectTab == 0) createService();
        else if (selectTab == 1) createRecommendService();
    }

    const remove = () => {
        setOpen(false);
        axios.delete(`/api/user/services/delete/${currentId}`, { user: getUserId().userId })
            .then(e => {
                loadData(`/api/user/myservices/${getUserId().userId}`, props.setMyService, { value: '_id', label: 'name' })
                props.notify({ type: 'success', text: 'Servicio eliminado', title: "Eliminar servicio" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al eliminar el servicio', title: "Eliminar Servicio" }))
    }

    return (
        <FlatCard>
            <Row>
                <Col md={6} className="text-left pl-5 pr-5">
                    <h4>Mis servicios</h4>
                </Col>
                <Col md={6} className="text-right">
                    <SecondaryBtn onClick={() => setOpenModal(true)}>Nuevo servicio</SecondaryBtn>
                </Col>
                <Col style={{ position: 'relative' }}>
                    {props.selected.map(e => {
                        let columns = [
                            { key: 'name', text: e.name, background: e.color, color: '#fff', alignTitle: 'center', minWidth: '100px' },
                            { key: 'min', text: 'Tarifa Normal', alignTitle: 'center', last: " CLP", alignContent: 'center' },
                            { key: 'max', text: 'Tarifa Emergencia', alignTitle: 'center', last: " CLP", alignContent: 'center' },
                            { key: 'time', text: 'Tiempo estimado', alignTitle: 'center', last: "Minutos", alignContent: 'center' },
                            {
                                key: 'controls', text: 'Acciones', alignTitle: 'center', alignContent: 'center', elem: (serv) => {
                                    return <BtnDelete onClick={() => {
                                        setCurrentId(serv._id)
                                        setOpen(true)
                                    }} />
                                }
                            },
                        ];

                        return (
                            <div className="p-5">
                                <Table autoHeight={false} columns={columns} data={e.servicios} background="#F9F9F9" color="#5A6170" backgroundNoData="#F1F3F6" paginationClass="table-buttons" exports={false} />
                            </div>
                        )
                    })}
                    <br /><br />

                    <ModalSimple width="800px" autoClose={false} open={openModal} accept={true} cancel={true} onClick={onClick} onClose={setOpenModal}>
                        <div className="text-center" style={{ margin: 10 }}>
                            <h4>Agregar un nuevo servicio</h4>
                            <p>Escoge una categoria y sus datos</p>
                        </div>
                        <ul className="nav nav-tabs pl-5 pr-5" id="myTab" role="tablist">
                            <li className="nav-item" onClick={() => setSelectTab(0)}>
                                <a className="nav-link active" id="serv-tab" data-toggle="tab" href="#serv" role="tab" aria-controls="serv" aria-selected="true">Crear servicio</a>
                            </li>
                            <li className="nav-item" onClick={() => setSelectTab(1)}>
                                <a className="nav-link" id="solicitud-tab" data-toggle="tab" href="#solicitud" role="tab" aria-controls="solicitud" aria-selected="false">Sugerir nuevo servicio</a>
                            </li>
                        </ul>
                        <div className="tab-content pt-5 pl-5 pr-5" id="myTabContent2" >
                            {/* <br />   */}
                            <div className="tab-pane fade show active" id="serv" role="tabpanel" aria-labelledby="serv-tab">
                                <Row className="text-left">
                                    <Col md={6}>
                                        <Select disabled={false} err={errService} label="Categoria de servicio" options={props.selected.map(e => ({ value: e._id, label: e.name }))} placeholder="" value={newService} onChange={v => {
                                            setNewService(v);
                                            axios.get(`/api/tarifa/get/services/${v.value}`)
                                                .then(res => setServices(res.data.data))
                                                .catch(err => console.log(err))
                                        }} />
                                    </Col>
                                    <Col md={6}>
                                        <Select disabled={false} err={errService} label="Servicio" options={services.map(e => ({ value: e._id, label: e.name }))} placeholder="Servicio" value={newServiceName} onChange={v => setNewServiceName(v)} />
                                    </Col>
                                </Row>
                                {/* <br /> */}
                                {selectService != undefined && selectService != null ? <Row>
                                    <Col>
                                        <div class="alert alert-warning mb-5" role="alert">
                                            <Row>
                                                <Col style={{ fontSize: 16 }} className="text-left" md={9}>Tarifa sugerida: Min: ${selectService.min} Max: ${selectService.max}</Col>
                                                <Col md={3}><span style={{ cursor: 'pointer', fontSize: 16 }} onClick={() => { setNewServiceMin(selectService.min); setNewServiceMax(selectService.max) }}>Usar tarifa</span></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row> : null}
                                <Row className="text-left">
                                    <Col md={4}>
                                        <InputNumber err={errServiceMin} placeholder="" label="Tarifa Normal." value={newServiceMin} onChange={value => {
                                            setNewServiceMin(value);
                                            if (selectService != undefined || selectService != null) {
                                                if (selectService.min < parseInt(value)) {
                                                    setTarifaBaja(true);
                                                } else {
                                                    setTarifaBaja(false);
                                                }
                                            }
                                        }} />
                                    </Col>
                                    <Col md={4}>
                                        <InputNumber err={errServiceMax} placeholder="" label="Tarifa Emergencia." value={newServiceMax} onChange={value => {
                                            setNewServiceMax(value)
                                            if (selectService != undefined || selectService != null) {
                                                if (selectService.max < parseInt(value)) {
                                                    setTarifaAlta(true);
                                                } else {
                                                    setTarifaAlta(false);
                                                }
                                            }
                                        }} />
                                    </Col>
                                    <Col md={4}>
                                        <Select disabled={false} err={errServiceTime} label="Tiempo estimado trabajo" options={timeInterval} placeholder="" value={newServiceTime} onChange={v => setNewServiceTime(v)} />
                                    </Col>
                                    <Col>
                                        {errServiceMinMayorToMax ? <p className="errPrice">Tarifa Min. no puede ser mayor o igual a Tarifa Max.</p> : null}
                                    </Col>
                                </Row>
                                {/* <br /> */}
                                {tarifaBaja ? <Row>
                                    <Col>
                                        <div class="alert alert-dark mb-5" role="alert">
                                            <Row>
                                                <Col style={{ fontSize: 14 }} className="text-left" md={12}>Tu tarifa minima se encuentra sobre el precio promedio de los otros proveedores</Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row> : null}
                                {tarifaAlta ? <Row>
                                    <Col>
                                        <div class="alert alert-dark mb-5" role="alert">
                                            <Row>
                                                <Col style={{ fontSize: 14 }} className="text-left" md={12}>Tu tarifa maxima se encuentra sobre el precio promedio de los otros proveedores</Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row> : null}
                                {/* <br /> */}
                                <Row className="text-left">
                                    <Col md={12}>
                                        <InputTextArea placeholder="" label="Descripción" value={newServiceDesc} onChange={value => setNewServiceDesc(value)} />
                                    </Col>
                                </Row>

                            </div>

                            {/* SOLICITUD DE SERVICIO */}

                            <div className="tab-pane fade show" id="solicitud" role="tabpanel" aria-labelledby="solicitud-tab">

                                <Row className="text-left">
                                    <Col md={6}>
                                        <Select disabled={false} err={eService} label="Categoria de servicio" options={props.selected.map(e => ({ value: e._id, label: e.name }))} placeholder="" value={catService} onChange={v => setCatService(v)} />
                                    </Col>
                                    <Col md={6}>
                                        <InputText err={eServiceName} label="Nombre del servicio" value={nameService} onChange={setNameService} />
                                    </Col>
                                </Row>
                                {/* <br /> */}
                                <Row className="text-left">
                                    <Col md={4}>
                                        <InputNumber err={eServiceMin} placeholder="" label="Tarifa Normal." value={minService} onChange={setMinService} />
                                    </Col>
                                    <Col md={4}>
                                        <InputNumber err={eServiceMax} placeholder="" label="Tarifa Emergencia." value={maxService} onChange={setMaxService} />
                                    </Col>
                                    <Col md={4}>
                                        <Select disabled={false} err={eServiceTime} label="Tiempo estimado trabajo" options={timeInterval} placeholder="" value={timeService} onChange={setTimeService} />
                                    </Col>
                                    <Col>
                                        {eServiceMinMayorToMax ? <p className="errPrice">Tarifa Min. no puede ser mayor o igual a Tarifa Max.</p> : null}
                                    </Col>
                                </Row>

                                <Row className="text-left">
                                    <Col md={12}>
                                        <InputTextArea placeholder="" label="Descripción" value={descService} onChange={setDescService} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </ModalSimple>
                </Col>
            </Row>
            <ModalCalendar open={open}>
                <div className="pt-4 pb-0">
                    <Row>
                        <Col className="text-center" md={12}>
                            <h4 className="pb-5"> ¿Desea eliminar este servicio ?</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" md={6}>
                            <WhiteBtn onClick={() => setOpen(false)}>Cancelar</WhiteBtn>
                        </Col>
                        <Col className="text-center" md={6}>
                            <SecondaryBtn onClick={() => remove()}>Eliminar</SecondaryBtn>
                        </Col>
                    </Row>
                </div>
            </ModalCalendar>
        </FlatCard>
    )
}

TabService.defaultProps = {
    selected: []
}


export default connect(TabService)