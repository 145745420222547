import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { Row, Col } from "../../components/grid";
import { FlatCard } from '../../components/card';
import { SecondaryBtn, WhiteBtn } from '../../components/buttons';
import { connect } from '../../store'
import { setToken } from '../../lib/Router'
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from './locale'
import moment from 'moment'
import { SelectLight } from '../../components/input';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

const HorarioTabla = (props) => {
    const [data, setData] = useState(null);
    const [dateStart, setDateStart] = useState({ from: null, to: null })
    const [dateAusencia, setDateAusencia] = useState(null)

    useEffect(() => {
        setData(props.user.availability)
        let vac = props.user.vacaciones;
        let ausencia = props.user.diaAusencia;

        if (ausencia != undefined) {
            let a = moment(ausencia).format('DD-MM-YYYY').split('-').map(e => parseInt(e));
            setDateAusencia({ day: a[0], month: a[1], year: a[2] })
        }

        if (vac != undefined) {

            if (vac.init == null || vac.end == null) {
                setDateStart({ from: null, to: null });
            } else {
                let init = moment(vac.init == null ? new Date() : vac.init).format('DD-MM-YYYY').split('-').map(e => parseInt(e));
                let end = moment(vac.end == null ? new Date() : vac.end).format('DD-MM-YYYY').split('-').map(e => parseInt(e));

                setDateStart({
                    from: { day: init[0], month: init[1], year: init[2] },
                    to: { day: end[0], month: end[1], year: end[2] }
                });
            }
        }
    }, [])



    return (
        <>
            <Row>
                <Col xs={12} md={12}>
                    <FlatCard>
                        <Row className="p-4">
                            <Col>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ background: '#FF8B03', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 30 }}>
                                                        <p style={{ color: "#fff", margin: 0 }}>VACACIONES</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <DatePicker
                                                    value={dateStart}
                                                    onChange={setDateStart}
                                                    inputPlaceholder="Selecciona tu vacaciones"
                                                    shouldHighlightWeekends
                                                    calendarClassName="responsive-calendar"
                                                    minimumDate={utils().getToday()}
                                                    locale={localeEs}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ background: '#FF8B03', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%', height: 30 }}>
                                                        <p style={{ color: "#fff", margin: 0 }}>DÍA DE AUSENCIA</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <DatePicker
                                                    value={dateAusencia}
                                                    onChange={setDateAusencia}
                                                    inputPlaceholder="Selecciona un día de ausencia"
                                                    shouldHighlightWeekends
                                                    calendarClassName="responsive-calendar"
                                                    locale={localeEs}
                                                    minimumDate={utils().getToday()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </FlatCard>

                    <FlatCard>
                        <Row>
                            <Col>
                                <HourColumn data={data} dateStart={dateStart} dateAusencia={dateAusencia} notify={props.notify} userId={props.user._id} />
                            </Col>
                        </Row>
                    </FlatCard>
                </Col>
            </Row>
        </>
    )
}


export default connect(HorarioTabla);

const RowHour = (props) => {

    const [zma, setMa] = useState(null);
    const [zmm, setMm] = useState(null);
    const [ztd, setTd] = useState(null);
    const [znc, setNc] = useState(null);

    const m = [
        { value: "06:00", label: "06:00", pos: 0 },
        { value: "07:00", label: "07:00", pos: 1 },
        { value: "08:00", label: "08:00", pos: 2 },
        { value: "09:00", label: "09:00", pos: 3 },
        { value: "10:00", label: "10:00", pos: 4 },
        { value: "11:00", label: "11:00", pos: 5 },
        { value: "12:00", label: "12:00", pos: 6 }
    ]
    const mm = [
        { value: "13:00", label: "13:00", pos: 1 },
        { value: "14:00", label: "14:00", pos: 2 },
        { value: "15:00", label: "15:00", pos: 3 },
        { value: "16:00", label: "16:00", pos: 4 },
        { value: "17:00", label: "17:00", pos: 5 },
        { value: "18:00", label: "18:00", pos: 6 },
    ]

    const t = [
        { value: "19:00", label: "19:00", pos: 1 },
        { value: "20:00", label: "20:00", pos: 2 },
        { value: "21:00", label: "21:00", pos: 3 },
        { value: "22:00", label: "22:00", pos: 4 },
        { value: "23:00", label: "23:00", pos: 5 },
        { value: "00:00", label: "00:00", pos: 6 }
    ]

    const n = [
        { value: "01:00", label: "01:00", pos: 1 },
        { value: "02:00", label: "02:00", pos: 2 },
        { value: "03:00", label: "03:00", pos: 3 },
        { value: "04:00", label: "04:00", pos: 4 },
        { value: "05:00", label: "05:00", pos: 5 },
        { value: "06:00", label: "06:00", pos: 6 }
    ]

    const change = (value) => {
        if (props.onChangeEnabled != undefined) props.onChangeEnabled(value)
    }

    useEffect(() => {
        if (props.data != undefined) {
            setMa(props.data['ma']);
            setMm(props.data['mm']);
            setTd(props.data['td']);
            setNc(props.data['nc']);
        }
    }, [props.data])

    useEffect(() => {
        if (props.onChange != undefined) props.onChange({ ma: zma, mm: zmm, td: ztd, nc: znc });
    }, [zma, zmm, ztd, znc])

    return (
        <Row>
            <Col md={2}>
                <NameZone name={props.title} checked={props.disabled} onChange={change} />
            </Col>
            <Col md={10}>
                <Row>
                    <Col md={3} style={{ background: "#F1F3F6" }}>
                        <SelectZone disabled={props.disabled} options={m} value={zma && zma['hour']} enabled={zma && zma['enabled']} onChange={v => { setMa(v); }} />
                    </Col>
                    <Col md={3} style={{ background: "#FFFFFF" }}>
                        <SelectZone disabled={props.disabled} options={mm} value={zmm && zmm['hour']} enabled={zmm && zmm['enabled']} onChange={v => { setMm(v); }} />
                    </Col>
                    <Col md={3} style={{ background: "#F1F3F6" }}>
                        <SelectZone disabled={props.disabled} options={t} value={ztd && ztd['hour']} enabled={ztd && ztd['enabled']} onChange={v => { setTd(v); }} />
                    </Col>
                    <Col md={3} style={{ background: "#FFFFFF" }}>
                        <SelectZone disabled={props.disabled} options={n} value={znc && znc['hour']} enabled={znc && znc['enabled']} onChange={v => { setNc(v); }} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const SelectZone = (props) => {
    const [diff, setDiff] = useState([]);
    const [desde, setDesde] = useState(null);
    const [hasta, setHasta] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [data, setData] = useState(null)

    useEffect(() => {
        let value = props.value;

        if (value != null) {
            if (value['from'] != undefined) {
                let val = props.options.find(e => {
                    if (e.value === value['from']) return e;
                });
                if (val != undefined) setDesde(val);
            }

            if (value['to'] != undefined) {
                let val = props.options.find(e => e.value === value['to']);
                if (val != undefined) setHasta(val);
            }
        } else {
            setDesde(null);
            setHasta(null);
        }
    }, [props.value])

    const changeStartHour = (hh) => {
        setDiff(props.options.filter(e => e.pos > hh.pos));
        setDesde(hh);
        setHasta(null);
        if (props.onChange != undefined) props.onChange(callData(enabled, hh != null ? hh.value : null, hasta != null ? hasta.value : null));
    }

    const changeEndHour = (hh) => {
        setHasta(hh);
        if (props.onChange != undefined) props.onChange(callData(enabled, desde != null ? desde.value : null, hh != null ? hh.value : null));
    }

    useEffect(() => {
        if (!props.enabled) {
            setDesde(null);
            setHasta(null);
        }

        setEnabled(props.enabled);
    }, [props.enabled])

    const changeEnebled = (v) => {
        setEnabled(v);
        if (props.onChange != undefined) props.onChange(callData(v, v ? desde : null, v ? hasta : null));
    }

    const callData = (e = enabled, from = desde, to = hasta) => {
        return {
            enabled: e,
            hour: from == null || to == null ? null : { from: from, to: to }
        }
    }

    useEffect(() => {
        if (!props.disabled) {
            setEnabled(!props.disabled)
        }
    }, [props.disabled])

    return (
        <Row>
            <Col md={2} style={{ padding: 0 }}>
                <Checkbox small={true} disabled={!props.disabled} checked={enabled} onChange={changeEnebled} name={''} />
            </Col>
            <Col md={5} style={{ padding: 0 }}>
                <SelectLight value={desde} options={props.options} disabled={props.disabled && enabled} label="Desde" placeholder="Desde" onChange={changeStartHour} />
            </Col>
            <Col md={5} style={{ padding: 0 }}>
                <SelectLight value={hasta} options={diff} disabled={props.disabled && enabled} label="Hasta" placeholder="Hasta" onChange={changeEndHour} />
            </Col>
        </Row>
    )
}

SelectZone.defaultProps = {
    options: [],
    value: null
}

const RowFooter = (props) => {

    return (
        <Row>
            <Col md={12}><br /><br /></Col>
        </Row>
    )
}

const RowTitleZones = (props) => {

    return (
        <Row>
            <Col md={2}></Col>
            <Col md={10}>
                <Row className="text-center">
                    <Col md={3}>
                        <Row>
                            <Col md={12} className="p-0">
                                <div style={{ background: "#F1F3F6", color: "#222", fontWeight: 500, fontSize: 16 }} className="p-3">Mañana</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={12} className="p-0">
                                <div style={{ background: "#F1F3F6", color: "#222", fontWeight: 500, fontSize: 16 }} className="p-3">Media Tarde</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={12} className="p-0">
                                <div style={{ background: "#F1F3F6", color: "#222", fontWeight: 500, fontSize: 16 }} className="p-3">Tarde</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={12} className="p-0">
                                <div style={{ background: "#F1F3F6", color: "#222", fontWeight: 500, fontSize: 16 }} className="p-3">Noche</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const RowHeaders = (props) => {

    return (
        <Row>
            <Col md={2}></Col>
            <Col md={10}>
                <Row className="text-center">
                    <Col md={3}>
                        <Row>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Desde</div>
                            </Col>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Hasta</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Desde</div>
                            </Col>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Hasta</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Desde</div>
                            </Col>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Hasta</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Desde</div>
                            </Col>
                            <Col md={6} className="p-0">
                                <div style={{ background: "#FF8B03", color: "#fff" }} className="p-3">Hasta</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const HourColumn = (props) => {

    let zoneDefault = {
        ma: { enabled: false, hour: null },
        mm: { enabled: false, hour: null },
        td: { enabled: false, hour: null },
        nc: { enabled: false, hour: null }
    }

    const [events, setEvents] = useState({ lun: zoneDefault, mar: zoneDefault, mie: zoneDefault, jue: zoneDefault, vie: zoneDefault, sab: zoneDefault, dom: zoneDefault })
    const [lun, setLun] = useState(false);
    const [mar, setMar] = useState(false)
    const [mie, setMie] = useState(false)
    const [jue, setJue] = useState(false)
    const [vie, setVie] = useState(false)
    const [sab, setSab] = useState(false)
    const [dom, setDom] = useState(false)

    const [dataLun, setDataLun] = useState(null)
    const [dataMar, setDataMar] = useState(null)
    const [dataMie, setDataMie] = useState(null)
    const [dataJue, setDatajue] = useState(null)
    const [dataVie, setDataVie] = useState(null)
    const [dataSab, setDataSab] = useState(null)
    const [dataDom, setDataDom] = useState(null)

    const updateStateEvent = (name, data) => {
        let a = Object.assign({}, events);
        a[name] = data;
        setEvents(a);
    }

    useEffect(() => {
        if (props.data != undefined) {
            let obj = {}
            if (props.data['lun'] != undefined) {
                obj['lun'] = props.data['lun'];
                let result = [];
                for (let i in props.data['lun']) result.push(props.data['lun'][i].enabled);
                setLun(props.data['lun']['enabled'])
                setDataLun(props.data['lun']);
            }
            else {
                props.data['lun'] = zoneDefault;
                setDataLun(zoneDefault);
                setLun(false);
            }
            if (props.data['mar'] != undefined) {
                obj['mar'] = props.data['mar'];
                let result = [];
                for (let i in props.data['mar']) result.push(props.data['mar'][i].enabled);
                setMar(props.data['mar']['enabled'])
                setDataMar(props.data['mar'])
            }
            else {
                props.data['mar'] = zoneDefault;
                setMar(false)
                setDataMar(zoneDefault)
            }

            if (props.data['mie'] != undefined) {
                obj['mie'] = props.data['mie'];
                let result = [];
                for (let i in props.data['mie']) result.push(props.data['mie'][i].enabled);
                setMie(props.data['mie']['enabled'])
                setDataMie(props.data['mie'])
            }
            else {
                props.data['mie'] = zoneDefault;
                setMie(false)
                setDataMie(zoneDefault)
            }

            if (props.data['jue'] != undefined) {
                obj['jue'] = props.data['jue'];
                let result = [];
                for (let i in props.data['jue']) result.push(props.data['jue'][i].enabled);
                setJue(props.data['jue']['enabled'])
                setDatajue(props.data['jue'])
            }
            else {
                props.data['jue'] = zoneDefault;
                setJue(false)
                setDatajue(props.data['jue'])
            }

            if (props.data['vie'] != undefined) {
                obj['vie'] = props.data['vie'];
                let result = [];
                for (let i in props.data['vie']) result.push(props.data['vie'][i].enabled);
                setVie(props.data['vie']['enabled'])
                setDataVie(props.data['vie'])
            }
            else {
                props.data['vie'] = zoneDefault;
                setVie(false)
                setDataVie(zoneDefault)
            }

            if (props.data['sab'] != undefined) {
                obj['sab'] = props.data['sab'];
                let result = [];
                for (let i in props.data['sab']) result.push(props.data['sab'][i].enabled);
                setSab(props.data['sab']['enabled'])
                setDataSab(props.data['sab'])
            }
            else {
                props.data['sab'] = zoneDefault;
                setSab(false)
                setDataSab(zoneDefault)
            }

            if (props.data['dom'] != undefined) {
                obj['dom'] = props.data['dom'];
                let result = [];
                for (let i in props.data['dom']) result.push(props.data['dom'][i].enabled);
                setDom(props.data['dom']['enabled'])
                setDataDom(props.data['dom'])
            }
            else {
                props.data['dom'] = zoneDefault;
                setDom(false)
                setDataDom(zoneDefault)
            }

            setEvents(obj);
        }
    }, [props.data])

    const repeat = () => {
        setEvents({ lun: dataLun, mar: dataLun, mie: dataLun, jue: dataLun, vie: dataLun, sab: dataLun, dom: dataLun })
        setMar(lun);
        setDataMar(dataLun);
        setMie(lun);
        setDataMie(dataLun);
        setJue(lun);
        setDatajue(dataLun);
        setVie(lun);
        setDataVie(dataLun);
        setSab(lun);
        setDataSab(dataLun);
        setDom(lun);
        setDataDom(dataLun);
    }

    const save = () => {
        let elemDisabled = {
            ma: { hour: null, enabled: false },
            mm: { hour: null, enabled: false },
            td: { hour: null, enabled: false },
            nc: { hour: null, enabled: false }
        }

        let lu = lun ? dataLun : elemDisabled;
        let ma = mar ? dataMar : elemDisabled;
        let mi = mie ? dataMie : elemDisabled;
        let ju = jue ? dataJue : elemDisabled;
        let vi = vie ? dataVie : elemDisabled;
        let sa = sab ? dataSab : elemDisabled;
        let dx = dom ? dataDom : elemDisabled;

        let s = {
            availability: {
                lun: { enabled: lun, ...lu },
                mar: { enabled: mar, ...ma },
                mie: { enabled: mie, ...mi },
                jue: { enabled: jue, ...ju },
                vie: { enabled: vie, ...vi },
                sab: { enabled: sab, ...sa },
                dom: { enabled: dom, ...dx }
            },
            vacaciones: {
                init: new Date(moment({ day: props.dateStart.from.day, month: props.dateStart.from.month - 1, year: props.dateStart.from.year })),
                end: new Date(moment({ day: props.dateStart.to.day, month: props.dateStart.to.month - 1, year: props.dateStart.to.year }))
            },
            diaAusencia: props.dateAusencia != null ? new Date(moment({ day: props.dateAusencia.day, month: props.dateAusencia.month - 1, year: props.dateAusencia.year })) : props.dateAusencia
        }

        if (props.dateStart.from == null || props.dateStart.to == null) {
            props.notify({ type: 'error', text: 'Debes agregar tu fecha de inicio y termino de vacaciones', title: "Actualizar" })
        } else {
            axios.post(`/api/user/horarios/${props.userId}`, s)
                .then(res => {
                    setToken(res.data.token)
                    props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
                })
                .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
        }
    }

    return (
        <>
            <RowTitleZones />
            <RowHeaders />
            <RowHour onChangeEnabled={setLun} data={events['lun']} disabled={lun} title="Lunes" onChange={setDataLun} />
            <RowHour onChangeEnabled={setMar} data={events['mar']} disabled={mar} title="Martes" onChange={setDataMar} />
            <RowHour onChangeEnabled={setMie} data={events['mie']} disabled={mie} title="Miercoles" onChange={setDataMie} />
            <RowHour onChangeEnabled={setJue} data={events['jue']} disabled={jue} title="Jueves" onChange={setDatajue} />
            <RowHour onChangeEnabled={setVie} data={events['vie']} disabled={vie} title="Viernes" onChange={setDataVie} />
            <RowHour onChangeEnabled={setSab} data={events['sab']} disabled={sab} title="Sabado" onChange={setDataSab} />
            <RowHour onChangeEnabled={setDom} data={events['dom']} disabled={dom} title="Domingo" onChange={setDataDom} />
            <RowFooter />
            <br />
            <Row>
                <Col md={6} className="text-left">
                    <WhiteBtn onClick={() => repeat()}>Repetir todos los días</WhiteBtn>
                    <br /><br />
                </Col>
                <Col md={6} className="text-right">
                    <SecondaryBtn onClick={() => save()}>Guardar</SecondaryBtn>
                    <br /><br />
                </Col>
            </Row>
        </>
    )
}

const NameZone = (props) => {

    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ background: '#FFF', display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: '100%', height: 40 }}>
                <Checkbox checked={props.checked} onChange={change} name={props.name} />
            </div>
        </div>
    )
}

const Checkbox = (props) => {

    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v.target.checked);
    }

    const random = `checkbox-${Math.floor(Math.random() * (2000 - 1)) + 1}`

    return (
        <div class="custom-control custom-checkbox ml-4 mt-3">
            <input disabled={props.disabled} id={random} type="checkbox" class="custom-control-input" style={{ width: 20, margin: 25 }} checked={props.checked} onChange={change} />
            <label style={{ color: "#5A6170", margin: 0, fontSize: 16 }} class={`custom-control-label ${props.small ? 'custom-control-label-small' : ''}`} htmlFor={random}><span style={{ marginLeft: 15 }}>{props.name}</span></label>
        </div>
    )
}

Checkbox.defaultProps = {
    disabled: false,
    small: false
}