import React, { useEffect } from 'react'
import "./style.css";
import { connect } from '../../store'
import { getUserId } from '../../lib/Router'

const TableRanking = (props) => {


    const processData = (arr, index) => {
        let length = arr.length;
        let data = [];
        if (length > index) {
            if(arr[0] != undefined && index != 0) data.push(arr[0])
            data.push(arr[index]);
            if(index == 0 && length > 2) data.push(arr[1])
            if(arr[length - 1] != undefined && arr[length - 1] != index) data.push(arr[length - 1])
            return data;
        }
    }

    const getData = (data) => {
        const findIndex = data.findIndex(i => i._id === getUserId().userId);
        const arr = data.map((e, i) => {
            e['index'] = i + 1;
            if (e['_id'] === getUserId().userId) e['valid'] = true;
            return e;
        })

        return processData(arr, findIndex);
    }

    return (
        <div>
            {props.data.map(e => {
                return (
                    <div className="service table-responsive pt-4">
                        <table className="table table-borderless text-center">
                            <thead>
                                <tr className="bg-table-thead">
                                    <th scope="col">Lugar</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Nombre Categoría</th>
                                    <th scope="col">Puntaje</th>
                                </tr>
                            </thead>
                            <tbody className="bg-table-tbody">
                                {getData(e.ranking).map(d => {
                                    return (<tr style={{ background: d.valid ? e.color : "white" }}>
                                        <td className="word-position-ranking" style={{ color: d.valid ? "#fff" : "rgb(90, 97, 112)", width: 100 }}>{d.index} {d.index == 1 ? "(Primer lugar)" : d.index == e.ranking.length ? "(Último lugar)" : ""}</td>
                                        <td style={{ color: d.valid ? "#fff" : "rgb(90, 97, 112)", width: 100 }}>{d.name} {d.lastname}</td>
                                        <td style={{ color: d.valid ? "#fff" : "rgb(90, 97, 112)", width: 100 }}>{e.name}</td>
                                        <td style={{ color: d.valid ? "#fff" : "rgb(90, 97, 112)", width: 100 }} className="number-position-ranking">{d.total}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <hr></hr>
                    </div>
                )
            })}
        </div>
    )
}

export default connect(TableRanking);