import {
    PUSH_NOTIFIACTION,
    REMOVE_NOTIFIACTION,
    UPDATE_VERSION,
    TOGGLE_SIDEBAR,
    SET_HEAD,
    TOOLS,
    GET_PROJECTS,
    TOGGLE_SIDESHEETS,
    SET_VIEWPORT,
    SET_GOBACK,
    ACTIVE_MENU,
    SET_FORM_TYPE,
    USER_ID_ACTIVATE,
    SHEDULE_EVENTS,
    SHEDULE_PENDINGS,
    REGIONS,
    CAT_SERVICE,
    MY_SERVICES
} from './variables'

const setVersion = (data) => ({ type: UPDATE_VERSION, payload: data })
const setSidebar = (data) => ({ type: TOGGLE_SIDEBAR, payload: data })
const setSidesheet = (data) => ({ type: TOGGLE_SIDESHEETS, payload: data })
const setHead = (data) => ({ type: SET_HEAD, payload: data })
const setTools = (data) => ({ type: TOOLS, payload: data })
const getProjects = (data) => ({ type: GET_PROJECTS, payload: data })
const setViewport = (data) => ({ type: SET_VIEWPORT, payload: data })
const setGoback = (data) => ({ type: SET_GOBACK, payload: data })

const setActiveMenu = (data) => ({ type: ACTIVE_MENU, payload: data })
const pushNotification = (data) => ({ type: PUSH_NOTIFIACTION, payload: data });
const removeNotification = (data) => ({ type: REMOVE_NOTIFIACTION, payload: data });
const setFormType = (data) => ({ type: SET_FORM_TYPE, payload: data });
const setUserIdActivate = (data) => ({ type: USER_ID_ACTIVATE, payload: data });
const setSheduleEvents = (data) => ({ type: SHEDULE_EVENTS, payload: data });
const setShedulePendings = (data) => ({ type: SHEDULE_PENDINGS, payload: data });
const setRegions = (data) => ({ type: REGIONS, payload: data })
const setCatService = (data) => ({ type: CAT_SERVICE, payload: data })
const setMyService = (data) => ({ type: MY_SERVICES, payload: data })


export default {
    setVersion,
    setSidebar,
    setHead,
    setTools,
    getProjects,
    setSidesheet,
    setViewport,
    setGoback,
    setActiveMenu,
    pushNotification,
    removeNotification,
    setFormType,
    setUserIdActivate,
    setSheduleEvents,
    setShedulePendings,
    setRegions,
    setCatService,
    setMyService
}