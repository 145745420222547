import socketIOClient from "socket.io-client";
import { getToken } from './lib/Router'
import endopint from './Endpoint';
// import jwt_decode from "jwt-decode"
// import CryptoJS from "crypto-js";
import axios from 'axios'

// const keysecret = 'g7VG[.7k[N*B$kG';

const options = {
    rememberUpgrade: true,
    transports: ['websocket'],
    secure: true,
    rejectUnauthorized: false,
    forceNew: true,
    path: '/ws',
    query: {
        auth: getToken()
    }
}

const io = socketIOClient(endopint, options);

export const getDataIO = (event = '', api = '', reducer, select, callback) => {
    io.on(event, () => {
        axios.get(api)
            .then(response => {
                if (reducer != undefined) {
                    if (select != undefined) {
                        reducer(response.data.data.map(e => {
                            e['value'] = e[select['value']]
                            e['label'] = e[select['label']]
                            return e;
                        }))
                    } else reducer(response.data.data);
                }

                if (callback != undefined) callback();
            })
            .catch(err => console.log(err))
    });
}

export default io;



// const getData = () => {
//     try {
//         return jwt_decode(getToken()).user;
//     } catch (e) {
//         return { _id: null };
//     }
// }

// export const send = (msg = '', obj = {}, all = []) => {
//     let u = getData();
//     let req = { tenantId: getTenantId(), client: u, key: u._id, all }
//     let data = { req, data: obj }
//     let d = CryptoJS.AES.encrypt(JSON.stringify(data), keysecret).toString();
//     io.emit(msg, d);
// }

// export const on = (msg = '', cb) => {
//     io.on(msg, data => {
//         const bytes = CryptoJS.AES.decrypt(data, keysecret)
//         let res = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//         let u = getData();
//         if (res.config.key === u._id || res.config.all.includes(getData()._id)) {
//             if (cb != undefined) {
//                 cb(res.data, res.status, res.config, res.config.key);
//             }
//         }

//     });
// }

// export const onError = (c) => {
//     io.on('error', (err) => {
//         const bytes = CryptoJS.AES.decrypt(err, keysecret)
//         // if (c != undefined) c(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         if (c != undefined) c(err);
//     })
// }