import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios';
import { getUserId, setToken } from '../../lib/Router'
import { FlatCard } from '../../components/card';
import { connect } from '../../store';
import { SecondaryBtn } from '../../components/buttons';
import { Row, Col } from "../../components/grid";
import { InputText, Select } from "../../components/input";
import './style.css';

const typeCta = [
    { value: "Corriente", label: "Corriente" },
    { value: "Vista", label: "Vista" },
    { value: "Ahorro", label: "Ahorro" },
    { value: "Rut", label: "Rut" }
]

const banks = [
    { value: 'Banco Bci', label: 'Banco Bci' },
    { value: 'Banco de Chile ', label: 'Banco de Chile' },
    { value: 'Banco Estado', label: 'Banco Estado' },
    { value: 'Banco Santander', label: 'Banco Santander' },
    { value: 'Banco BICE', label: 'Banco BICE' },
    { value: 'Banco CrediChile ', label: 'Banco CrediChile ' },
    { value: 'Banco Edwards Citi', label: 'Banco Edwards Citi' },
    { value: 'Banco Falabella', label: 'Banco Falabella' },
    { value: 'Banco Internacional', label: 'Banco Internacional' },
    { value: 'Banco Itaú', label: 'Banco Itaú' },
    { value: 'Banco Ripley', label: 'Banco Ripley' },
    { value: 'Banco Security', label: 'Banco Security' },
    { value: 'Scotiabank', label: 'Scotiabank' },
    { value: 'Otro', label: 'Otro' }
]

const TabBank = (props) => {
    let user = getUserId();
    const [bank, setBank] = useState({ value: user.user.bank, label: user.user.bank });
    const [typeBank, setTypeBank] = useState(user.user.typeCta);
    const [numBank, setNumBank] = useState(user.user.number_cta);
    const [otherBank, setOtherBank] = useState('');
    const [errBank, setErrBank] = useState(false);
    const [errTypeBank, setErrTypeBank] = useState(false);
    const [errNumBank, setErrNumBank] = useState(false);
    const [active, setActive] = useState(false)

    const saveBank = () => {
        axios.post(`/api/user/bank/${user.userId}`, { bank: bank.value, typeCta: typeBank.value, number_cta: numBank, otherBank: otherBank })
            .then(response => {
                setToken(response.data.token);
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    useEffect(() => {
        if (!user.user.bank && !user.user.typeCta && !user.user.number_cta) {
            setActive(true);
        }
    })

    return (
        <FlatCard>
            <br /><br /><br />
            <Row>
                <Col md={bank === 'Otro' || bank.value === 'Otro' ? 3 : 4}>
                    <Select disabled={!active} err={errBank} label="Banco" options={banks} placeholder="Seleccionar banco" value={bank} onChange={v => setBank(v)} />
                </Col>
                {bank === 'Otro' || bank.value === 'Otro' ? <Col md={3}>
                    <InputText disabled={!active} placeholder="Nombre del banco" label="Banco" value={otherBank} onChange={value => setOtherBank(value)} />
                </Col> : null}
                <Col md={bank === 'Otro' || bank.value === 'Otro' ? 3 : 4}>
                    <Select disabled={!active} err={errTypeBank} label="Tipo cuenta" options={typeCta} placeholder="Seleccionar banco" value={typeBank} onChange={v => setTypeBank(v)} />
                </Col>
                <Col md={bank === 'Otro' || bank.value === 'Otro' ? 3 : 4}>
                    <InputText disabled={!active} err={errNumBank} placeholder="" label="Número de cuenta" value={numBank} onChange={value => setNumBank(value)} />
                </Col>
            </Row>
            <br /><br />
            <Row className="p-0 text-right">
                <Col>
                    <label style={{ marginRight: 20, width: 200 }}><input type="checkbox" onChange={(v) => setActive(v.target.checked)} /> Modificar datos</label>
                    <SecondaryBtn onClick={saveBank}>Guardar</SecondaryBtn>
                </Col>
            </Row>
        </FlatCard>
    )
}


export default connect(TabBank)