import React, { useEffect, useState } from 'react';
import Rutas from '../../routes/Routes';
// Componentes
import Navbar from '../../components/navbar/index.js';
import Sidebar from '../../components/sidebar/index.js';
import './style.css';
import { connect } from '../../store';
import Notifications from '../../components/notify';
import { getUserId } from '../../lib/Router'
import { getDataIO } from '../../sockets'
import { loadData } from '../../utils'
import { logo } from '../../logos'
import 'leaflet/dist/leaflet.css';

const Layout = (props) => {
    const userId = getUserId().userId;
    const [desktop, setDesktop] = useState(true);

    useEffect(() => {
        loadData('/regions', props.setRegions, { value: 'region', label: 'region' });
        loadData('/api/catservice', props.setCatService, { value: '_id', label: 'name' });
        loadData(`/api/calendar/events/all/${userId}`, props.setSheduleEvents, null); // res => console.log(res)
        loadData(`/api/calendar/events/pendings/${userId}`, props.setShedulePendings)
        loadData(`/api/user/myservices/${userId}`, props.setMyService, { value: '_id', label: 'name' })

        getDataIO('new_shedule_pending', `/api/calendar/events/pendings/${userId}`, props.setShedulePendings)
        getDataIO('new_shedule_event', `/api/calendar/events/all/${userId}`, props.setSheduleEvents)
        getDataIO('notify_user_services', `/api/user/myservices/${userId}`, props.setMyService, { value: '_id', label: 'name' })
    }, [])

    const mq = () => {
        if (window.matchMedia("(min-width: 991px)").matches) setDesktop(true);
        else setDesktop(false);
    }

    useEffect(() => {
        mq();
        window.addEventListener('resize', mq);
    }, [])

    return (
        <div>
            <Navbar />
            {desktop ? <div className={`d-flex ${props.store.sidebar ? '' : 'toggled'}`} id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Rutas />
                </div>
            </div> : <div style={{ padding: 20, display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh", flexDirection: "column" }}>
                {logo}
                <p style={{ marginTop: 20 }}>Ingresa desde la app Tikitaka</p>
            </div>}

            <Notifications />
        </div>
    )
}

export default connect(Layout);

