import React, { useState, Fragment, useEffect } from 'react';
import { SecondaryBtn, PrimaryBtn } from '../../components/buttons';
import './style.css';
import queryString from 'query-string'
import OtpInput from 'react-otp-input';
import { connect } from '../../store'
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { logo } from '../../logos'

const RecoverPassword = (props) => {
    const history = useHistory();
    const [otp, setOtp] = useState('');
    const [user, setuser] = useState('');
    const [typeAlert, setTypeAlert] = useState(0);
    const [resend, setResend] = useState(false);
    const [activation, setActivation] = useState(false);
    const [activationText, setActivationText] = useState('Verificando código de activación');
    const [activationState, setActivationState] = useState(false);
    const handleChange = (otp) => setOtp(otp);

    const verification = (otp, user) => {
        setActivationText('Verificando código de activación');
        setTypeAlert(2);
        setActivation(true);
        setActivationState(false);
        Axios.post("/verification", {
            code: otp,
            user: user
        })
            .then(res => {
                if (res.data.success) {
                    setActivationText('Código verificado con exito');
                    setActivationState(true);
                    setTypeAlert(1);
                } else {
                    setActivationText('Código de validación no valido');
                    setActivationState(false);
                    setTypeAlert(0)
                }

            })
            .catch(err => {
                setActivationText('Código de validación no valido');
                setActivationState(false);
            });
    }

    const resendCode = (user) => {
        Axios.post(`/resendcode/${user}`, {})
            .then(res => {
                props.notify({ type: 'success', title: "Verifiación", text: "Enviando código de verificación", time: 3000 });
                setResend(true);
            })
            .catch(err => {
                console.log(err);
                props.notify({ type: 'err', title: "Verifiación", text: "Error al enviar el código de verificación", time: 3000 })
            });
    }

    useEffect(() => {
        if (props.location.search) {
            let q = queryString.parse(props.location.search);
            if (q.code != undefined) {
                setOtp(String(q.code));
                if (q.u != undefined) verification(q.code, q.u);
            }
        }
    }, [])

    return (
        <Fragment>
            <div className="container d-flex align-items-center d-flex justify-content-center activate-container" style={{ height: '80vh' }}>
                <div className="row codeRow">
                    <div className="col-12 text-center">
                        {logo}
                        <h5>Ingresa el código de verificación</h5>
                        <form className="mx-auto">
                            <div className="codeDiv mb-4">
                                <OtpInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    separator={<span> - </span>}
                                    inputStyle="form-control codeInput"
                                />
                            </div>

                            {activation ? <div>{
                                typeAlert == 0 ? <div className="alert alert-danger" role="alert">{activationText}</div> :
                                    typeAlert == 1 ? <div className="alert alert-success" role="alert">{activationText}</div> :
                                        <div className="alert alert-info" role="alert">{activationText}</div>
                            }</div> : null}

                            <div className="mb-4">
                                {activationState ? <PrimaryBtn className="btn-rounded-border" onClick={() => props.setFormType('login')}>Iniciar sesión</PrimaryBtn> : <SecondaryBtn className="btn-rounded-border" onClick={() => verification(otp, props.store.user_id_activate)}>Aceptar</SecondaryBtn>}
                            </div>
                        </form>
                        <p>¿No llego el código? <span onClick={() => resendCode(props.store.user_id_activate)} style={{ color: "rgb(250, 88, 5)", fontWeight: 600, cursor: 'pointer' }}>Volver a enviar</span></p>
                        {resend ? <p>Código enviado</p> : null}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(RecoverPassword);
