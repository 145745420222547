import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Circle, Marker, useMapEvent, useMapEvents, useMap } from 'react-leaflet'
import { Row, Col } from '../../components/grid'
import L from 'leaflet'
import { SecondaryBtn } from '../../components/buttons';
import axios from 'axios';
import { setToken } from '../../lib/Router'
import { connect } from '../../store'
import useWindowDimensions from './resize'
// import marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl:
        "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png",
    iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
});

const Distance = (props) => {
    const [load, setLoad] = useState(false)
    const [km, setKm] = useState(15);
    const [pos, setPos] = useState([-33.4447602, -70.6511804]);
    const fillBlueOptions = { fillColor: '#FA5805', color: '#FA5805' }
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        var geoCode = new window.GeoCode();
        geoCode.geolookup(props.address).then(result => {
            const { lat, lng } = result[0];
            setPos([lat, lng])
        });

        setKm(props.distance != undefined ? parseInt(props.distance) : 20);
    }, [])

    const save = () => {
        axios.post(`/api/user/distance/${props.userId}`, { distance: parseInt(km) })
            .then(res => {
                setToken(res.data.token)
                props.notify({ type: 'success', text: 'Datos actualizados', title: "Actualizar" })
            })
            .catch(err => props.notify({ type: 'error', text: 'Error al actualizar', title: "Actualizar" }))
    }

    return <div>
        <Row>
            <Col md={4}>
                <Row className="text-center" style={{ display: 'flex', alignItems: "center" }}>
                    <Col md={12}>
                        <br /><br />
                        <h4>Seleccione la distancia en que se mostraran sus servicios</h4>
                        <br />
                    </Col>
                    <Col md={3}>
                        <h5>10 Kms</h5>
                    </Col>
                    <Col md={6}>
                        <input className="form-control" type="range" min={10} max={60} value={km} onChange={v => setKm(parseInt(v.target.value))} />
                    </Col>
                    <Col md={3}>
                        <h5>60 Kms</h5>
                    </Col>
                    <Col md={12}>
                        <h5>{km} Kms</h5>
                    </Col>
                    <Col md={12} className="text-right">
                        <br /><br />
                        <SecondaryBtn onClick={() => save()}>Guardar</SecondaryBtn>
                    </Col>
                </Row>
            </Col>
            <Col md={8} onFocus={() => setLoad(true)}>
                <MapContainer center={pos} zoom={10} style={{ width: width, height: height }}>
                    <Resizer />
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                    <Circle center={pos} radius={km * 1000} pathOptions={fillBlueOptions}></Circle>
                    <Marker position={pos} />
                    

                </MapContainer>
            </Col>
        </Row>
    </div>
}


export default connect(Distance);

const Resizer = () => {
    const map = useMap();
    setTimeout(() => {
        map.invalidateSize();
    }, 250);

    return null
}


