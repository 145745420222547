const color = "#fff";
const size = 15;

const miPanel = <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9285 5.86597L7.67856 0.136794C7.47491 -0.0495051 7.16173 -0.0449641 6.9638 0.147048L1.06101 5.8762C0.959961 5.97428 0.902832 6.1092 0.902832 6.24997V14.4791C0.902832 14.7668 1.13598 15 1.42367 15H5.76395C6.05161 15 6.28479 14.7668 6.28479 14.4791V10.6597H8.71534V14.4791C8.71534 14.7668 8.94849 15 9.23618 15H13.5765C13.8641 15 14.0973 14.767 14.0973 14.4791V6.25C14.0973 6.10398 14.0362 5.96476 13.9285 5.86597ZM13.0556 13.9583H9.75702V10.1389C9.75702 9.85123 9.52385 9.61805 9.23618 9.61805H5.76397C5.47631 9.61805 5.24313 9.85123 5.24313 10.1389V13.9583H1.94451V6.47031L7.33672 1.2368L13.0556 6.47916V13.9583H13.0556Z" fill="white" />
</svg>


const Boucher = <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0625 3.09375H7.17507L5.33437 1.20149C5.22844 1.09274 5.08313 1.03125 4.93125 1.03125H0.9375C0.420557 1.03125 0 1.45181 0 1.96875V13.0312C0 13.5482 0.420557 13.9688 0.9375 13.9688H14.0625C14.5794 13.9688 15 13.5482 15 13.0312V4.03125C15 3.51431 14.5794 3.09375 14.0625 3.09375ZM13.875 12.8438H1.125V2.15625H4.69368L6.53437 4.04851C6.64031 4.15726 6.78562 4.21875 6.9375 4.21875H13.875V12.8438Z" fill="white" />
</svg>


const calendar = <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0696 1.44568H0.930356C0.420146 1.44568 0.00506592 1.85103 0.00506592 2.34928V14.0963C0.00506592 14.5946 0.420146 14.9999 0.930356 14.9999H14.0696C14.5798 14.9999 14.9949 14.5946 14.9949 14.0963V2.34931C14.9949 1.85106 14.5798 1.44568 14.0696 1.44568ZM13.8846 13.9156H1.11543V2.53001H13.8846V13.9156H13.8846Z" fill="white" />
    <path d="M14.4399 5.0603H0.560364V6.14464H14.4399V5.0603Z" fill="white" />
    <path d="M3.89124 0C3.58461 0 3.33606 0.242725 3.33606 0.542168V3.43374C3.33606 3.73318 3.58461 3.97591 3.89124 3.97591C4.19787 3.97591 4.44642 3.73318 4.44642 3.43374V0.542168C4.44642 0.242725 4.19787 0 3.89124 0Z" fill="white" />
    <path d="M11.2936 0C10.987 0 10.7385 0.242725 10.7385 0.542168V3.43374C10.7385 3.73318 10.987 3.97591 11.2936 3.97591C11.6003 3.97591 11.8488 3.73318 11.8488 3.43374V0.542168C11.8488 0.242725 11.6003 0 11.2936 0Z" fill="white" />
</svg>



const miPerfil = <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.00165 3.70421C4.00165 1.59252 5.61638 0 7.75775 0C9.89911 0 11.5138 1.59252 11.5138 3.70438C11.5138 5.87774 9.82887 7.64581 7.75775 7.64581C5.68662 7.64581 4.00165 5.87774 4.00165 3.70421ZM5.13414 3.70438C5.13414 5.27408 6.31099 6.5511 7.75775 6.5511C9.20451 6.5511 10.3814 5.27408 10.3814 3.70438C10.3814 2.2166 9.2536 1.09488 7.75775 1.09488C6.23756 1.09488 5.13414 2.19234 5.13414 3.70438ZM0.528564 14.4526C0.528564 10.5811 3.09593 8.44895 7.75766 8.44895C12.4194 8.44895 14.9867 10.5813 14.9867 14.4526C14.9867 14.755 14.7332 15.0001 14.4205 15.0001H1.09481C0.782049 15.0001 0.528564 14.755 0.528564 14.4526ZM7.75766 9.54387C3.94569 9.54387 1.90438 11.0105 1.68166 13.9052H13.8339C13.611 11.0105 11.5696 9.54387 7.75766 9.54387Z" fill="white" />
</svg>




export default [
    {
        icon: miPanel,
        title: "Mi Panel",
        path: "/"
    },
    {
        icon: calendar,
        title: "Mi Agenda",
        path: "/agenda"
    },
    {
        icon: Boucher,
        title: "Mis Ganancias",
        path: "/boucher"
    },
    {
        icon: miPerfil,
        title: "Mi Perfil",
        path: "/perfil"
    },
    // {
    //     icon: miPerfil,
    //     title: "Simulador",
    //     path: "/simulate"
    // },
]