import React, { useState } from 'react';
import './index.css'

export const Card = (props) => {
    return (
        <div className="card-container">
            <div className="card-title">
                <span>{props.title}</span>
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}


export const FlatCard = (props) => {
    return (
        <div className={`card-container ${props.className}`} style={props.style}>
            <div className="card-body p-4">
                {props.children}
            </div>
        </div>
    )
}

FlatCard.defaultProps = {
    className: '',
    style: {}
}

export const CardWithTitle = (props) => {
    return (
        <div className="card-container" style={props.style}>
            <div className="card-body p-4">
                <div className="text-center">
                    <h4>{props.title}</h4>
                    <p>{props.subtitle}</p>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export const CardWithTitleBlack = (props) => {
    return (
        <div className="card-container bg-black" style={props.style}>
            <div className="card-body p-4">
                <div className="text-center color-white">
                    <h4>{props.title}</h4>
                    <p>{props.subtitle}</p>
                </div>
                {props.children}
            </div>
        </div>
    )
}

Card.defaultProps = {
    children: <div></div>,
    title: "",
    style: {}
}
