import React from 'react'
//import "./style.css";
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

const CircularProgress = (props) => {

    return (
        <div>
            <CircularProgressbarWithChildren
                value={props.value}
                strokeWidth={8}
                counterClockwise={false}
                circleRatio={1}
                styles={buildStyles({
                    rotation: 0.50,
                    strokeLinecap: 'round',
                    textSize: '14px',
                    pathTransitionDuration: 0.5,
                    pathColor: `rgba(${props.rgb}, ${props.value / 10})`,
                    textColor: '#000',
                    trailColor: '#EAEDF4',
                })}>
                <img
                    style={{ width: 50 }}
                    src={props.img}
                    alt="doge"
                />
            </CircularProgressbarWithChildren>
            <p className="text-center pt-3 m-0">
                <strong>{`${props.value}%`}</strong><br/>
                {props.attitudes}
            </p>
        </div>

    )
}

CircularProgress.defaultProps = {
    rgb: '33, 102, 222'
}

export default CircularProgress;