import React from 'react'
import './index.css'

const size = 15;

export const BtnSee = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-secondary buttonsTable" title="Ver" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 384 427" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M341.334 42.667H252.054C243.307 17.92 219.84 0 192 0C164.16 0 140.693 17.92 131.947 42.667H42.667C19.094 42.667 0 61.76 0 85.334V384C0 407.573 19.093 426.667 42.667 426.667H341.334C364.907 426.667 384.001 407.574 384.001 384V85.333C384 61.76 364.907 42.667 341.334 42.667ZM192 42.667C203.733 42.667 213.333 52.16 213.333 64C213.333 75.84 203.733 85.333 192 85.333C180.267 85.333 170.667 75.84 170.667 64C170.667 52.16 180.267 42.667 192 42.667ZM234.667 341.333H85.334V298.666H234.667V341.333ZM298.667 256H85.334V213.333H298.667V256ZM298.667 170.667H85.334V128H298.667V170.667Z" fill="black" />
            </svg>
        </button>
    )
}

export const BtnDelete = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-danger buttonsTable" title="Eliminar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 299 384" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.333 341.333C21.333 364.8 40.533 384 64 384H234.667C258.133 384 277.333 364.8 277.333 341.333V85.333H21.333V341.333ZM73.92 189.44L104.107 159.253L149.333 204.48L194.56 159.253L224.747 189.44L179.52 234.667L224.747 279.894L194.56 310.081L149.333 264.853L104.106 310.08L73.919 279.893L119.146 234.666L73.92 189.44Z" fill="black" />
                <path d="M224 21.333L202.666 0H96L74.666 21.333H0V64H298.666V21.333H224Z" fill="black" />
            </svg>
        </button>
    )
}

export const BtnEdit = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-primary buttonsTable" title="Editar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 342 427" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M213.333 0H42.666C19.2 0 0.213001 19.2 0.213001 42.667L0 384C0 407.467 18.987 426.667 42.453 426.667H298.666C322.133 426.667 341.333 407.467 341.333 384V128L213.333 0ZM170.666 341.333C126.933 341.333 89.493 314.986 72.959 277.333H109.439C122.986 296.64 145.279 309.333 170.559 309.333C211.839 309.333 245.226 275.946 245.226 234.666C245.226 193.386 211.839 159.999 170.559 159.999C141.652 159.999 116.906 176.532 104.532 200.639L138.665 234.666H53.333V149.333L81.066 177.066C100.053 147.627 133.013 128 170.666 128C229.546 128 277.333 175.787 277.333 234.667C277.333 293.547 229.546 341.333 170.666 341.333Z" fill="black" />
            </svg>

        </button>
    )
}

export const BtnModalAdd = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        !props.floatCircle ? <button style={{ position: props.position, bottom: props.bottom, left: props.left }} type="button" className={"btn btn-secondary " + (props.className ? props.className : "")} data-toggle="modal" data-target={"#" + props.modal}>
            {props.children}
        </button> : <button data-toggle="modal" data-target={"#" + props.modal} type="button" className="btn btn-primary buttonsFloatCircle" title="Editar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.98723 12.6007C7.20599 12.6007 7.39902 12.5107 7.547 12.3693C7.68856 12.2279 7.77863 12.0287 7.77863 11.8101V7.48465H12.1088C12.3276 7.48465 12.5206 7.39467 12.6686 7.25327C12.8102 7.11188 12.9003 6.91263 12.9003 6.69411C12.9003 6.25706 12.5464 5.90356 12.1153 5.90999H7.78507V1.5845C7.78507 1.14745 7.43119 0.793952 7.0001 0.800379C6.56257 0.800379 6.20869 1.15387 6.21512 1.5845V5.90999H1.88491C1.44739 5.90999 1.09351 6.26349 1.09994 6.69411C1.09994 7.13116 1.45382 7.48465 1.88491 7.47822H6.21512V11.8037C6.19582 12.2472 6.5497 12.6007 6.98723 12.6007Z" fill="white" stroke="white" />
            </svg>
        </button>
    )
}

BtnModalAdd.defaultProps = {
    floatCircle: false,
    position: 'relative',
    bottom: 0,
    left: 0
}

export const PrimaryBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className={"btn btn-primary " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

export const SecondaryBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button style={{ position: props.position, bottom: props.bottom, left: props.left }} type="button" className={"btn btn-secondary " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

SecondaryBtn.defaultProps = {
    position: 'relative',
    bottom: 0,
    left: 0
}

export const WhiteBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className={"btn btn-white " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

BtnModalAdd.defaultProps = {
    modal: 'exampleModal'
}
